<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: CustomersView.vue
Description:This file contains the combination of two tabs  Activation Completed  and Activation Pending.In the Activation Completed Part shows the customers who are associated with Company and Activation Pending part shows the customers still not associated with the Company.
-->
<template>
  <layout :no-aside="true" class="customers-view">
    <div>
      <tab-layout
        :componentList="componentList"
        :showComponent="tabName"
        :tab-click-handler="handleTabClick"
        @selectedComponent="handleSelectedComponent"
      ></tab-layout>
    </div>
    <div>
      <create-new-customer
        user-type="CUSTOMER"
        :model="formCustomer"
        :action="actionToPerform"
        :visible="addCustomerDialogVisible"
        @close="closeAddCustomerDialog"
      ></create-new-customer>

      <settings-table
        ref="erDataTables"
        v-loading="loading"
        element-loading-background="white"
        :tableData="tableData"
        :tableProps="elTableProps"
        :key="tabName"
        :total="total"
        :button="true"
        :columns="columnsFilterByTab"
        :columnselector_require="true"
        :column-selector-options="{
            keyToStoreDefaults: `customer-view-${tabName}`,
            keyToDecideDefaultCol: 'required',
        }"
        :selectedColumnHeadersSortable="true"
        placeHolderForSearch="Search_username"
        search-property="user_name"
        :addPadingToSearch="true"
        @cell-click="handleCellClick"
        @loadData="handleLoadData"
        @reload="handleLoadData"
        @cleared="handleLoadData"
        @add-item-click="handleAddCustomerClick"
      >
        <template slot="add-buttton">Add Customer</template>

        <template v-slot:name="{ row }">
          {{
            `${(row.data || { first_name: "--" }).first_name} ${
              (row.data || { last_name: "--" }).last_name
            }`
          }}
        </template>
        <template v-slot:login="{ row }">
          <er-button
            type="text"
            @click="handleLoginAsUser($event, row.data._id)"
            >Login as User</er-button
          >
        </template>
        <template v-slot:prev_day_schedule="{ row }">
          <el-switch
            :value="(row.data.device_config || {}).retain_previous_day_schedule"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @input="
              handleEnableFeature(
                $event,
                row.data,
                'retain_previous_day_schedule'
              )
            "
          ></el-switch>
        </template>
        <template v-slot:ui_edit="{ row }">
          <el-switch
            :value="(row.data.device_config || {}).device_ui_edit"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @input="handleEnableFeature($event, row.data, 'device_ui_edit')"
          ></el-switch>
        </template>
        <template v-slot:actions="{ row }">
          <el-button-group>
            <template v-if="tabName === 'unverified'">
              <er-button
                size="mini"
                :disabled="row.data.email_verified"
                title="Mark User Email as Verified"
                @click="handleActions(row.data, 'CONFIRM_EMAIL')"
              >
                <span class="material-icons-round">mark_email_read</span>
              </er-button>
              <er-button
                size="mini"
                :disabled="row.data.email_verified"
                title="Resend Email to user for verification"
                @click="handleActions(row.data, 'RESEND_EMAIL')"
              >
                <span class="material-icons-round">reply_all</span>
              </er-button>
              <er-button
                size="mini"
                :disabled="!((row.data.country && row.data.country.name === 'india') && (row.data.mobile && !row.data.mobile_verified))"
                title="Resend SMS to user for verification"
                @click="handleActions(row.data, 'RESEND_SMS')"
              >
                <span class="material-icons-round">send_to_mobile</span>
              </er-button>
              <er-button
                size="mini"
                :disabled="row.data.user_type !== 'ADMIN'"
                icon="el-icon-edit"
                 @click="handleCustomerEdit(row.data)"
              ></er-button>
              <er-button
                size="mini"
                :disabled="row.data.email_verified"
                title="Remove User"
                @click="handleActions(row.data, 'REMOVE_USER')"
              >
                <span class="material-icons-round">person_remove</span>
              </er-button>
            </template>
            <template v-else>
              <el-button
                size="mini"
                :disabled="row.data.user_type !== 'ADMIN'"
                icon="el-icon-plus"
                @click="handleAddDevice(row.data)"
              ></el-button>
              <el-button
                size="mini"
                :disabled="row.data.user_type !== 'ADMIN'"
                icon="el-icon-delete"
                @click="handleDeleteDevice(row.data)"
              ></el-button>
              <el-button
                size="mini"
                :disabled="row.data.user_type !== 'ADMIN'"
                icon="el-icon-edit"
                 @click="handleCustomerEdit(row.data)"
              ></el-button>
            </template>
          </el-button-group>
        </template>
        <template v-slot:signup_date="{ row }">
          {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.created_at)) }}
        </template>

        <template v-slot:v1_password_synced="{ row }">
          <center>
            <span
              v-if="row.data.v1_password_synced"
              class="material-icons-round"
              style="color: green"
            >
              vpn_key
            </span>
            <span v-else class="material-icons-round" style="color: #bdbdbdc7">
              vpn_key
            </span>
          </center>
        </template>

        <template v-slot:user_type="{ row }">
          <!-- <center> -->
            <span>
              {{ ftm__userTypeToText(row.data.user_type) || "-" }}
            </span>
          <!-- </center> -->
        </template>
        <template v-slot:total_devicesNestedColumn>
          <el-table-column label="Gateways" min-width="100">
            <template slot-scope="scope">{{
              scope.row.gateways_count
            }}</template>
          </el-table-column>
          <el-table-column label="Pondguards" min-width="100">
            <template slot-scope="scope">{{
              scope.row.pond_guards_count
            }}</template>
          </el-table-column>
          <el-table-column label="PondMothers" min-width="100">
            <template slot-scope="scope">{{
              scope.row.pond_mothers_count
            }}</template>
          </el-table-column>
          <el-table-column label="ShrimpTalks" min-width="100">
            <template slot-scope="scope">{{
              scope.row.shrimp_talks_count
            }}</template>
          </el-table-column>
        </template>
      </settings-table>
      <div>
        <component
          :is="component"
          :showDialog="dialogVisible"
          :action="action"
          :customer_details="selectedCustomer"
          @close_dialog="closeDialog"
        ></component>
      </div>
    </div>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import settingsTable from "@/components/base/settingsTable";
import addDevicesToUser from "@/components/superadmin/customer/addDevicesToUser";
import createNewCustomer from "@/components/superadmin/shared/createNewCustomer";
import layout from "@/components/superadmin/shared/layout";
import tabLayout from "@/components/superadmin/shared/tabLayout";
import dialogForm from "@/components/superadmin/shared/dialogForm";
import User from "@/model/user.js";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin, filtersMixin, adminDatesHandlerMixin],
  data: function() {
    return {
      tableData: [],
      total: 0,
      dialogBoxLoading: false,
      addCustomerDialogVisible: false,
      formCustomer: new User(),
      actionToPerform: "ADD",
      dialogVisible: false,
      loading: false,
      component: "addDevicesToUser",
      tabName: "verified",
      selectedCustomer: {},
      action: "ADD_DEVICES",
      queryParams: {},
      componentList: [
        {
          name: "verified",
          label: "Activation Completed"
        },
        {
          name: "unverified",
          label: "Activation Pending"
        },
        {
          name: "blocked",
          label: "Blocked Users"
        }
      ]
    };
  },
  components: {
    settingsTable,
    tabLayout,
    layout,
    addDevicesToUser,
    dialogForm,
    createNewCustomer
  },
  computed: {
    ...mapGetters("superadmin", {
      getCustomers: "getUsers",
      getCustomersObj: "getUsersObj",
      getUserIdIndexPmsObj: "getUserIdIndexPmsObj",
      getUserIdIndexPgsObj: "getUserIdIndexPgsObj",
      getUserIdIndexStsObj: "getUserIdIndexStsObj",
      getUserIdIndexGatewaysObj: "getUserIdIndexGatewaysObj"
    }),
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    },
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      return "calc(100vh - 180px)";
    },
    columnsObject: function() {
      if (!this.allowCustomerTab.includes("CUSTOMER_LOGIN") && !this.allowCustomerTab.includes("ADD/REMOVE_DEVICES_FROM_CUSTOMER")) {
        return [
          {
            label: "Name",
            required: true,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 150,
            fixed: "fixed"
          },
          {
            prop: "phone",
            label: "Phone Number",
            required: false,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 140
          },
          {
            prop: "v1_id",
            label: "V1 Id",
            required: false,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 100
          },
          {
            prop: "_id",
            label: "V2 Id",
            required: false,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 200
          },
          {
            prop: "country.name",
            label: "Country",
            required: false,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 100
          },
          {
            prop: "country.created_at",
            label: "SignUp Date",
            required: false,
            sortable: "custom",
            tab: ["verified", "unverified", "blocked"],
            minWidth: 150
          },
          {
            prop: "email",
            label: "Email Address",
            required: true,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 200
          },
          {
            prop: "mobile",
            label: "Mobile Number",
            required: true,
            tab: ["verified", "unverified"],
            sortable: false,
            minWidth: 200
          },
          {
            prop: "total_devices",
            label: "Total Devices",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "prev_day_schedule",
            label: "Prev Day Schedule",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "ui_edit",
            label: "UI Edit",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "user_type",
            label: "User Type",
            required: true,
            tab: ["verified"],
            minWidth: 150
          },
          {
            prop: "tags",
            label: "Tag",
            required: false,
            tab: ["verified", "unverified"],
            sortable: true,
            minWidth: 150
          },
          {
            prop: "v1_password_synced",
            label: "V1 Password Synced",
            required: false,
            tab: ["verified"],
            minWidth: 100
          }
        ];
      } else if (!this.allowCustomerTab.includes("CUSTOMER_LOGIN")) {
        return [
          {
            label: "Name",
            required: true,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 150,
            fixed: "fixed"
          },
          {
            prop: "phone",
            label: "Phone Number",
            required: false,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 140
          },
          {
            prop: "v1_id",
            label: "V1 Id",
            required: false,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 100
          },
          {
            prop: "_id",
            label: "V2 Id",
            required: false,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 200
          },
          {
            prop: "country.name",
            label: "Country",
            required: false,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 100
          },
          {
            prop: "country.created_at",
            label: "SignUp Date",
            required: false,
            sortable: "custom",
            tab: ["verified", "unverified", "blocked"],
            minWidth: 150
          },
          {
            prop: "email",
            label: "Email Address",
            required: true,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 200
          },
          {
            prop: "mobile",
            label: "Mobile Number",
            required: true,
            tab: ["verified", "unverified"],
            sortable: false,
            minWidth: 200
          },
          {
            prop: "total_devices",
            label: "Total Devices",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "prev_day_schedule",
            label: "Prev Day Schedule",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "ui_edit",
            label: "UI Edit",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "user_type",
            label: "User Type",
            required: true,
            tab: ["verified"],
            minWidth: 150
          },
          {
            prop: "tags",
            label: "Tag",
            required: false,
            tab: ["verified", "unverified"],
            sortable: true,
            minWidth: 150
          },
          {
            prop: "v1_password_synced",
            label: "V1 Password Synced",
            required: false,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "actions",
            label: "Actions",
            required: true,
            tab: ["verified", "unverified"],
            minWidth: 150
          }
        ];
      } else if (!this.allowCustomerTab.includes("ADD/REMOVE_DEVICES_FROM_CUSTOMER")) {
        return [
          {
            label: "Name",
            required: true,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 150,
            fixed: "fixed"
          },
          {
            prop: "phone",
            label: "Phone Number",
            required: false,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 140
          },
          {
            prop: "v1_id",
            label: "V1 Id",
            required: false,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 100
          },
          {
            prop: "_id",
            label: "V2 Id",
            required: false,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 200
          },
          {
            prop: "country.name",
            label: "Country",
            required: false,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 100
          },
          {
            prop: "country.created_at",
            label: "SignUp Date",
            required: false,
            sortable: "custom",
            tab: ["verified", "unverified", "blocked"],
            minWidth: 150
          },
          {
            prop: "email",
            label: "Email Address",
            required: true,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 200
          },
          {
            prop: "mobile",
            label: "Mobile Number",
            required: true,
            tab: ["verified", "unverified"],
            sortable: false,
            minWidth: 200
          },
          {
            prop: "total_devices",
            label: "Total Devices",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "prev_day_schedule",
            label: "Prev Day Schedule",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "ui_edit",
            label: "UI Edit",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "login_action",
            label: "Login",
            required: true,
            tab: ["verified"],
            minWidth: 150
          },
          {
            prop: "user_type",
            label: "User Type",
            required: true,
            tab: ["verified"],
            minWidth: 150
          },
          {
            prop: "tags",
            label: "Tag",
            required: false,
            tab: ["verified", "unverified"],
            sortable: true,
            minWidth: 150
          },
          {
            prop: "v1_password_synced",
            label: "V1 Password Synced",
            required: false,
            tab: ["verified"],
            minWidth: 100
          }
        ];
      } else {
        return [
          {
            label: "Name",
            required: true,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 150,
            fixed: "fixed"
          },
          {
            prop: "phone",
            label: "Phone Number",
            required: false,
            sortable: true,
            tab: ["verified", "unverified"],
            minWidth: 140
          },
          {
            prop: "mobile",
            label: "Mobile Number",
            required: true,
            sortable: true,
            tab: ["blocked"],
            minWidth: 140,
          },
          {
            prop: "v1_id",
            label: "V1 Id",
            required: false,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 100
          },
          {
            prop: "_id",
            label: "V2 Id",
            required: false,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 200
          },
          {
            prop: "country.name",
            label: "Country",
            required: false,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 100
          },
          {
            prop: "country.created_at",
            label: "SignUp Date",
            required: false,
            sortable: "custom",
            tab: ["verified", "unverified", "blocked"],
            minWidth: 150
          },
          {
            prop: "email",
            label: "Email Address",
            required: true,
            sortable: true,
            tab: ["verified", "unverified", "blocked"],
            minWidth: 200
          },
          {
            prop: "mobile",
            label: "Mobile Number",
            required: true,
            tab: ["verified", "unverified"],
            sortable: false,
            minWidth: 200
          },
          {
            prop: "total_devices",
            label: "Total Devices",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "prev_day_schedule",
            label: "Prev Day Schedule",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "ui_edit",
            label: "UI Edit",
            required: true,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "login_action",
            label: "Login",
            required: true,
            tab: ["verified"],
            minWidth: 150
          },
          {
            prop: "user_type",
            label: "User Type",
            required: true,
            tab: ["verified"],
            minWidth: 150
          },
          {
            prop: "tags",
            label: "Tag",
            required: false,
            tab: ["verified", "unverified"],
            sortable: true,
            minWidth: 150
          },
          {
            prop: "v1_password_synced",
            label: "V1 Password Synced",
            required: false,
            tab: ["verified"],
            minWidth: 100
          },
          {
            prop: "actions",
            label: "Actions",
            required: true,
            tab: ["verified", "unverified"],
            minWidth: 150
          }
        ];
      }
    },
    columnsFilterByTab: function() {
      this.refreshTableLayout();
      return this.columnsObject.filter((x) => x.tab.includes(this.tabName));
    },
    customerFields: function() {
      return [
        {
          type: "input",
          inputType: "text",
          label: "First Name",
          model: "first_name"
        },
        {
          type: "input",
          inputType: "text",
          label: "Last name",
          model: "last_name"
        },
        {
          type: "input",
          inputType: "text",
          label: "Mobile Number",
          model: "phone"
        },
        {
          type: "select",
          inputType: "object",
          value_key: "code",
          label: "Country",
          model: "country",
          listValues: []
        },
        {
          type: "input",
          inputType: "password",
          label: "Password",
          model: "password"
        },
        {
          type: "input",
          inputType: "password",
          label: "Confirm Password",
          model: "confirm_password"
        }
      ];
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllCustomers: "fetchAllUsers",
      clearUserData: "clearUserData",
      loginAsCustomer: "loginAsCustomer",
      confirmEmail: "confirmEmail",
      resendEmail: "resendEmail",
      resendSMS: "resendSMS",
      removeUser: "removeUser",
      enableFeature: "enableFeature"
    }),

    getNewCustomer() {
      return {
        first_name: "",
        last_name: "",
        email: "",
        country: "",
        phone: "",
        password: "",
        confirm_password: ""
      };
    },
    handleTabClick(tab) {},
    async handleSelectedComponent(component) {
      this.tabName = component;
      // await this.handleLoadData(this.queryParams);
    },
    async handleEnableFeature(value, userDetails, key) {
      try {
        const confirmMessage = {
          retain_previous_day_schedule: `Your are ${
            value ? "enabling" : "disabling"
          } the status of the retain previous day ui, Are you sure?`,
          device_ui_edit: `Your are ${
            value ? "enabling" : "disabling"
          } the status of the Device UI, Are you sure?`
        };
        await this.$confirm(confirmMessage[key], "Warning", {
          confirmButtonText: "ok",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        });
        this.loading = true;
        await this.enableFeature({
          user_id: userDetails._id,
          payload: { [key]: value }
        });
        this.$notify({
          type: "success",
          title: "Success",
          message: value ? "Feature Enabled" : "Feature Disabled"
        });
        await this.handleLoadData(this.queryParams);
      } catch (error) {
        if (error !== "cancel") {
          const errorCode = error.response.data.status_code;
          let message;
          switch (errorCode) {
            case 409:
              message = error.response.data.message;
              this.$notify({
                type: "error",
                title: "Failed",
                message
              });
              break;
            default:
              this.ehm__errorMessages(error, true);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async handleActions(userDetails, actionType) {
      try {
        this.loading = true;
        const actionToApiMap = {
          CONFIRM_EMAIL: {
            action: this.confirmEmail,
            message: "Email Address Verified Successfully",
            confirm_message:
              "Your are going to confirm the email of the user, Are you sure ?"
          },
          RESEND_EMAIL: {
            action: this.resendEmail,
            message: "Verification Email Sent Successfully",
            confirm_message:
              "Your are going to resend verification email to the user, Are you sure ?"
          },
          RESEND_SMS: {
            action: this.resendSMS,
            message: "Verification SMS Sent Successfully",
            confirm_message:
              "Your are going to resend verification SMS to the user, Are you sure ?"
          },
          REMOVE_USER: {
            action: this.removeUser,
            message: "User Deleted Successfully",
            confirm_message: "Your are going to remove the user, Are you sure ?"
          }
        };
        await this.$confirm(
          actionToApiMap[actionType].confirm_message,
          "Warning",
          {
            confirmButtonText: "ok",
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }
        );
        await actionToApiMap[actionType].action({ user_id: userDetails._id });
        this.$notify({
          type: "success",
          title: "Success",
          message: actionToApiMap[actionType].message
        });
        await this.handleLoadData(this.queryParams);
      } catch (error) {
        if (error !== "cancel") {
          const errorCode = error.response.data.status_code;
          let message;
          switch (errorCode) {
            case 409:
              message = error.response.data.message;
              this.$notify({
                type: "error",
                title: "Failed",
                message
              });
              break;
            default:
              this.ehm__errorMessages(error, true);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    handleAddDevice(customer) {
      try {
        this.dialogVisible = true;
        this.action = "ADD_DEVICES";
        this.selectedCustomer = customer;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    handleDeleteDevice(customer) {
      try {
        this.dialogVisible = true;
        this.action = "DELETE_DEVICES";
        this.selectedCustomer = customer;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    async closeDialog(event) {
      this.dialogVisible = false;
      if (event !== "close") {
        await this.handleLoadData(this.queryParams);
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        query.include_devices_count = true;
        if (this.tabName === "blocked") {
          query.blocked_users_only = 'true';
        } else {
          query.email_not_verified_users_only = !(this.tabName === "verified");
        }
        this.queryParams = query;
        const response = await this.fetchAllCustomers({
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.$store.getters["superadmin/getUsers"];
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.refreshTableLayout();
        this.loading = false;
      }
    },
    refreshTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.erDataTables) {
          this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
        }
      });
    },
    handleCellClick(row, column, cell, event) {
      if (column.label === this.columnsObject[0].label) {
        this.clearUserData();
        this.$router.push({
          name: "CustomerDetails",
          params: { customer_id: row._id }
        });
      }
    },
    async handleLoginAsUser(event, userId) {
      const loading = this.$loading({ fullscreen: true });
      try {
        await this.loginAsCustomer(userId);
        this.$router.push(
          "/user/",
          () => {
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("Welcome_to_pondlogs"),
              duration: 5000,
              type: "success"
            });
            loading.close();
          },
          (err) => {
          //   this.$notify({
          //     title: this.$t("failed"),
          //     message: this.$t("Something_went_wrong_try_again"),
          //     duration: 5000,
          //     type: "error"
          //   });
            console.warn(err)
            loading.close();
          }
        );
      } catch (err) {
        this.$notify({
          title: this.$t("failed"),
          message: err.response.data.message,
          duration: 5000,
          type: "error"
        });
        loading.close();
      }
    },
    handleAddCustomerClick() {
      this.actionToPerform = "ADD"
      this.addCustomerDialogVisible = true;
    },
    handleCustomerEdit(customerDetails) {
      this.actionToPerform = "EDIT";
      this.formCustomer = customerDetails;
      this.showActionDialog(true);
    },
    showActionDialog(value) {
      this.actionDialogVisible = value;
      this.addCustomerDialogVisible = true
    },
    async closeAddCustomerDialog(message) {
      this.addCustomerDialogVisible = false;
      if (message === "user_created") {
        await this.handleLoadData(this.queryParams);
      }
    }
  }
};
</script>

<style lang="scss">
.customers-view {
  // height: calc(100vh - 190px) !important;
  .material-icons-round {
    font-size: 13px;
  }
  .location-name {
    text-transform: capitalize;
  }
  .el-table__body-wrapper {
    @include responsiveProperty(height, 360px, 500px, 600px);
    overflow-y: scroll;
  }
  @include responsiveProperty(--height-gutter, 190px, 195px, 205px)
    // .el-table--scrollable-x
    //   .el-table--scrollable-y
    //   .el-table--enable-row-transition {
    //   height: calc(100vh - 192px);
    // }
    // .device-apis-table.sc-table {
    //   .el-table
    //     .el-table--fit
    //     .el-table--scrollable-y
    //     .el-table--enable-row-transition {
    //     height: calc(100vh - 190px) !important;
    //   }
    // }
    // .el-table__body-wrapper {
    //   height: calc(100% - 36px) !important;
    // };;;;;;;;;;;;
}
</style>
