<template>
  <div class="customer-abw-table-view">
    <layout-toolbar>
      <span v-if="selectedView !== 'TABLE'" style="font-size: 14px">Smooth</span>
      <el-switch
        v-if="selectedView !== 'TABLE'"
        v-model="isSmoothGraph"
        active-color="#0A2463"
      >
      </el-switch>
      <el-radio-group
        class="radio-btn-group"
        v-model="selectedView"
        size="mini"
        @change="handleChangeInView"
      >
        <el-radio-button label="GRAPH">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30.579 22.934"
          >
            <path
              d="M19.877 15.29h2.293a.822.822 0 00.764-.764V6.5a.822.822 0 00-.764-.764h-2.293a.822.822 0 00-.764.764v8.027a.822.822 0 00.764.763zm5.734 0H27.9a.822.822 0 00.764-.764V.764A.822.822 0 0027.9 0h-2.29a.822.822 0 00-.764.764v13.761a.822.822 0 00.764.765zm-17.2 0H10.7a.822.822 0 00.764-.764v-4.2a.822.822 0 00-.764-.764H8.409a.822.822 0 00-.764.764v4.2a.822.822 0 00.764.764zm5.734 0h2.293a.822.822 0 00.764-.764V2.676a.822.822 0 00-.764-.764h-2.295a.822.822 0 00-.764.764v11.849a.822.822 0 00.764.765zm15.481 3.822H3.822V.956A.955.955 0 002.867 0H.956A.955.955 0 000 .956v20.067a1.911 1.911 0 001.911 1.911h27.713a.955.955 0 00.956-.956v-1.91a.955.955 0 00-.956-.956z"
            />
          </svg>
        </el-radio-button>
        <el-radio-button label="TABLE">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.347 17.804"
          >
            <path
              d="M18.439 0H1.908A1.908 1.908 0 000 1.908V15.9a1.908 1.908 0 001.908 1.9h16.531a1.908 1.908 0 001.908-1.9V1.908A1.908 1.908 0 0018.439 0zM8.9 15.26H2.543v-3.815H8.9zm0-6.358H2.543V5.087H8.9zm8.9 6.358h-6.355v-3.815H17.8zm0-6.358h-6.355V5.087H17.8z"
            />
          </svg>
        </el-radio-button>
      </el-radio-group>
    </layout-toolbar>
    <el-row class="pond-lab-tests-tab-body" v-if="selectedView === 'TABLE'">
        <er-data-tables
          ref="table"
          :el-table-props="elTableProps"
          :tableData="userPondsABWData"
          :columns="columnsObject"
          :action-column="false"
          uniqueId="pond-abw-tab"
          type="white-header-table"
          size="mini"
          :columnSelector="false"
        >
        <template v-slot:date="{ row }">
          {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.date, 'dd-MMM-yy')) }}
        </template>
        <template v-slot:survival_percentage="{ row }">
          {{ ftm__formatEmpty(row.data.survival_percentage) }}
        </template>
        <template v-slot:farm_biomass="{ row }">
          {{ ftm__formatEmpty(row.data.farm_biomass) }}
        </template>
        <template v-slot:doc="{ row }">
          {{ ftm__formatEmpty(row.data.doc) }}
        </template>
        <template v-slot:awg="{ row }">
          {{ ftm__formatEmpty(row.data.awg) }}
        </template>
        <template v-slot:abw="{ row }">
          {{ ftm__formatEmpty(row.data.abw) }}
        </template>
        <template v-slot:farm_biomass_header="scope">
          {{ scope.header.label }} &nbsp;
          <el-tooltip class="item" effect="dark" content="Biomass from customer given pond data (ABW, survival)" placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </template>
        <template v-slot:biomass_estimate_header="scope">
          {{ scope.header.label }} &nbsp;
          <el-tooltip class="item" effect="dark" content="Biomass Model Estimation, Deviation Percentage" placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </template>
        <template v-slot:biomass_estimate_NestedColumn>
            <el-table-column label="Eruvaka">
                <!-- <template slot-scope="scope"> -->
                  <el-table-column
                      v-for="(estimation, index) of nestedColummns"
                      :key="`${estimation.label}_${index}`"
                      :label="estimation.label"
                      min-width="130">
                      <template slot="header">
                        <p>{{ estimation.label }}</p>
                        &nbsp;
                        <el-tooltip class="item" effect="dark" :content="estimation.abbreviation" placement="top">
                          <i class="el-icon-info"></i>
                        </el-tooltip>
                      </template>
                    <template slot-scope="{ row }">{{
                      ftm__formatEmpty(row.estimations.length > 0 ? row.estimations[index].value : 0, "--") + (row.estimations.length > 0 && row.estimations[index].value > 0 && row.farm_biomass > 0 ?  " (" + getBiomassEstimationPercentage(row, index) + '%)' : '')
                    }}</template>
                  </el-table-column>
                <!-- </template> -->
            </el-table-column>
            <el-table-column label="Feed Model (Skretting)" min-width="120">
              <template slot="header">
                <p>
                  Feed Model (Skretting)
                </p>
                &nbsp;
                <el-tooltip class="item" effect="dark" content="Biomass from feed data, Skretting feeding model" placement="top">
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </template>
              <template slot-scope="{ row }">{{
                ftm__formatEmpty(row.skretting_biomass, "--") + (row.skretting_biomass > 0 && row.farm_biomass > 0 ?  " (" + getSkrettingBiomassPercentage(row) + '%)' : '')
              }}</template>
            </el-table-column>
          </template>
        </er-data-tables>
    </el-row>
    <el-row class="pond-lab-tests-tab-body" v-else element-loading-background="white">
      <customerABWChart :userPondsABWData="userPondsABWData" :isSmoothGraph="isSmoothGraph"></customerABWChart>
    </el-row>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import customerABWChart from "./graphs/customerABWChart.vue";
// import { mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin, filtersMixin, adminDatesHandlerMixin],
  components: {
    customerABWChart
  },
  props: ['userPondsABWData'],
  data() {
    return {
      selectedView: 'TABLE',
      isSmoothGraph: false
      }
  },
  computed: {
    // ...mapGetters("superadmin", {
    //   getUserPondsABWData: "getUserPondsABWData"
    // }),
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        stripe: true,
        align: 'center',
        defaultSort: {
          prop: "doc",
          order: "descending"
        }
      };
    },
    getRespTableHeight() {
      return "calc(100vh - 260px)";
    },
    nestedColummns() {
      return [
          {
            prop: "Multiplier (lb)",
            label: "Simple",
            abbreviation: "Biomass from feed, ABW, growth",
            required: true,
            minWidth: 50,
          },
          {
            prop: "CM-A",
            label: "Complex-A",
            abbreviation: "Biomass from feed, weather, and complex parameters",
            required: true,
            minWidth: 50
          },
          {
            prop: "CM-N_model ",
            label: "Complex-N",
            abbreviation: "Biomass from feed, weather, and complex parameters",
            required: true,
            minWidth: 50,
          },
          {
            prop: "CM-EXP-N_model",
            label: "Complex(EXP)-N",
            abbreviation: "Biomass from feed, weather, and complex parameters",
            required: true,
            minWidth: 50
          },
        ]
    },
    columnsObject() {
      return {
        doc: {
            prop: "doc",
            label: "DOC",
            required: true,
            minWidth: 80,
          },
        abw: {
            prop: "abw",
            label: "ABW (g)",
            required: true,
            minWidth: 80
          },
        awg: {
            prop: "awg",
            label: "AWG",
            required: true,
            minWidth: 80
          },
          survival_percentage: {
            prop: "survival_percentage",
            label: "Survival (%)",
            required: true,
            minWidth: 100
          },
        farm_biomass: {
            prop: "farm_biomass",
            label: "Farm Biomass (lb)",
            abbreviation: "Biomass from customer given pond data (ABW, survival)",
            required: true,
            minWidth: 120
          },
        biomass_estimate: {
            prop: "skretting_biomass",
            label: "Biomass Estimate (lb)",
            abbreviation: "Biomass from feed data, Skretting feeding model",
            required: true,
            minWidth: 150
          },
        date: {
            prop: "date",
            label: "Date",
            required: true,
            minWidth: 100
          }
      }
    }
  },
  methods: {
    handleChangeInView(value) {
      this.selectedView = value;
    },
    getBiomassEstimationPercentage(row, index) {
      const percentageData = (((row.estimations[index].value - row.farm_biomass) / row.farm_biomass) * 100).toFixed(0);
      return percentageData !== "-0" ? percentageData : 0;
      },
    getSkrettingBiomassPercentage(row) {
      const percentageData = (((row.skretting_biomass - row.farm_biomass) / row.farm_biomass) * 100).toFixed(0);
      return percentageData !== "-0" ? percentageData : 0;
    }
  }
}
</script>
<style lang="scss">
.customer-abw-table-view {
  @include responsiveProperty(--height-gutter, 190px, 195px, 205px);
  .radio-btn-group {
    display: inline-flex;
    align-items: center;
    line-height: 1.5;
    .el-radio:not(:last-child) {
      margin-right: 10px;
    }
    .el-radio-button {
      .icon {
        @include responsiveProperty(width, 1em, 1.3em, 1.5em);
        @include responsiveProperty(height, 1em, 1.3em, 1.5em);

        fill: black;
      }
      &.is-active {
        .icon path {
          fill: white;
        }
        .el-radio-button__inner {
          background-color: #0a2463 !important;
          border-color: #0a2463 !important;
          box-shadow: -1px 0 0 0 #0a2463;
          -webkit-box-shadow: -1px 0 0 0 #0a2463;
        }
      }
      .el-radio-button__inner {
        padding: 5px 11px;
        .el-radio__inner {
          width: 15px;
          height: 15px;
          &:hover {
            border-color: #333333;
          }
        }
      }
    }
  }
  .er-data-tables .el-table td .cell, .er-data-tables .el-table th .cell{
    justify-content: center;
    text-align: center;
  }
  .el-table__body-wrapper {
    @include responsiveProperty(height, 360px, 500px, 600px);
    overflow-y: scroll;
  }
}
</style>
