<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: customerListIndividualDetailsSA.vue
Description:THis file contains the combination of itemListSideMenu, customerDetails components
-->
<template>
  <layout
    :no-aside="true"
    v-loading="loading"
    element-loading-background="white"
    class="customer-individual-details-sa"
  >
    <splitpanes class="default-theme">
      <!-- <pane :size="20" :max-size="40" :min-size="10">
        <item-list-side-bar
          :item-list="customerList"
          :init-item-id="customer_id"
          :showProperty="['first_name', 'last_name', 'email']"
          item-type="customers"
          :property-seperator="(v1, v2, v3) => `${v1} ${v2} (${v3})`"
          search-str-query-param="user_name"
          :totalItemsCount="totalItemsCount"
          :dataFetchMethod="loadCustomerData"
          @cleared="loadCustomerData"
          @loadData="loadCustomerData"
          @itemClicked="handleCustomerSelected"
        ></item-list-side-bar>
      </pane> -->
      <pane>
        <customer-details
          :customer-details="customerDetails"
          @refresh="initComponent"
          @itemClicked="handleCustomerSelected"
        ></customer-details>
      </pane>
    </splitpanes>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import layout from "@/components/superadmin/shared/layout";
// import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import customerDetails from "@/components/superadmin/customer/customerDetails";
import { mapActions, mapGetters } from "vuex";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
export default {
  mixins: [errorHandlerMixin],
  props: ["customer_id"],
  components: {
    layout,
    // itemListSideBar,
    customerDetails,
    Splitpanes,
    Pane
  },
  data: function () {
    return {
      data: { customer_id: "" },
      loading: false,
      customer_data: {},
      totalItemsCount: 100
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      // getCustomers: "getUsers",
      getCustomersObj: "getUsersObj"
    }),
    customerDetails() {
      // return this.getCustomersObj[this.customer_id];
      return this.customer_data;
    },
    // customerList() {
    //   return this.getCustomers;
    // }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllCustomers: "fetchAllUsers",
      fetchACustomerDetails: "fetchAUserDetails",
      clearUserData: "clearUserData",
      fetchACustomerParsingKeys: "fetchACustomerParsingKeys"
    }),
    async initComponent() {
      try {
        this.loading = true;
        this.clearUserData();
        // await this.fetchAllCustomers({
        //   infinite_scroll: true,
        //   params: {
        //     page: 1,
        //     limit: 19,
        //     order_by: "first_name",
        //     order_type: "asc",
        //     include_devices_count: false
        //   }
        // });
        await this.callStoreOnCustomerDetials(this.customer_id);
        await this.callStoreOnParsingACustomerKeys(this.customer_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadCustomerData({ query, infinite_scroll = false }) {
      query.include_devices_count = false;
      const response = await this.fetchAllCustomers({
        infinite_scroll: infinite_scroll,
        params: query
      });

      this.totalItemsCount = response.total;
    },
    async callStoreOnParsingACustomerKeys(customer_id) {
      try {
        await this.fetchACustomerParsingKeys({ user_id: customer_id });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async callStoreOnCustomerDetials(customer_id) {
      try {
        this.customer_data = await this.fetchACustomerDetails({
          user_id: customer_id,
          params: { include_devices: false }
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleCustomerSelected(customer_id) {
      try {
        this.loading = true;
        this.$router.replace(customer_id);
        this.callStoreOnCustomerDetials(customer_id);
        this.callStoreOnParsingACustomerKeys(customer_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.customer-individual-details-sa {
  .splitpanes.default-theme .splitpanes__pane {
    background-color: unset;
  }
}
</style>
