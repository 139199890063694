<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: accountManagerTableSA.vue
Description: This file contains the list of the Account Manager associated with the company and add new Account by the access of the superadmin
-->
<template>
  <div class="log-table">
    <er-column-selector
      :columns="computedColumns"
      keyToStoreDefaults="activity_log_filters"
      keyToDecideDefaultCol="required"
      :allow-check="true"
      :title="'Column Visibility'"
      @change-selection="handleChangeColumnSelection"
    ></er-column-selector>
    <ag-grid-vue
      v-loading="getTableLoadingStatus"
      ref="agGridActivityLog"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      :columnDefs="columnsArr"
      :pagination="true"
      :rowData="tableData"
      :frameworkComponents="frameworkComponents"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import agGridDateTimeCompareFilter from "@/components/superadmin/shared/agGridDateTimeCompareFilter";
// import errorAlertStringMapping from utils
import errorAlertStringMapping from "@/utils/errorAlertStringMapping.js";

extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin, adminDatesHandlerMixin],
  data: function() {
    return {
      loading: false,
      total: 0,
      queryParams: {},
      tableProps: {
        size: "small",
        defaultSort: {
          prop: "first_name",
          order: "ascending"
        }
      },
      gridOptions: {
        defaultColDef: {
          resizable: true
        }
      },
      gridApi: undefined,
      gridColumnApi: undefined
    };
  },
  components: {
    AgGridVue
  },
  computed: {
    ...mapGetters("superadmin/activityLog", {
      getSelectedLogTypes: "getSelectedLogTypes",
      getActivityLogDataBySelectedFilters:
        "getActivityLogDataBySelectedFilters",
      getTableLoadingStatus: "getTableLoadingStatus"
    }),
    computedColumns: function() {
      const columns = this.$lodash.cloneDeep(
        this.columnsArr.reduce((acc, curr) => {
          acc[curr.colId] = curr;
          return acc;
        }, {})
      );
      // Object.keys(columns).forEach(x => {
      //   columns[x].label = this.$t(columns[x].label);
      // });
      Object.keys(columns).forEach((x) => {
        columns[x].label = columns[x].headerName;
      });
      return columns;
    },
    columnsArr: function() {
      return [
        {
          headerName: "Client",
          field: "client",
          colId: "client",
          sortable: false,
          filter: true,
          required: true,
          visible: this.isIntersect(
            [
              "SCHEDULES",
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              "POND_DEVICES", // "POND_PM_MAP",
              "ABW",
              // "PROFILE_SETTINGS",
              "PREFERENCES_SETTINGS",
              "USER_SETTINGS",
              "POND_SETTINGS",
              "POND_ALERT",
              "USER_LOCATIONS",
              "MANUAL_POND_FEEDINGS",
              "PONDS_LAB_TEST",
              "PONDS_MEDICINES",
              "POND_SCHEDULE_SETS",
              "PONDS_HARVEST",
              "USER_LABS",
              "RESOURCE_VENDORS",
              "USER_RESOURCES",
              "STOCK_FEEDS",
              "STOCK_MEDICINES",
              "PONDS_AQUASIMS"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "OS",
          field: "os",
          colId: "os",
          sortable: false,
          filter: true,
          required: false,
          visible: this.isIntersect(
            [
              "SCHEDULES",
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              "POND_DEVICES", // "POND_PM_MAP",
              "ABW",
              // "PROFILE_SETTINGS",
              "PREFERENCES_SETTINGS",
              "USER_SETTINGS",
              "POND_SETTINGS",
              "POND_ALERT",
              "USER_LOCATIONS",
              "MANUAL_POND_FEEDINGS",
              "PONDS_LAB_TEST",
              "PONDS_MEDICINES",
              "POND_SCHEDULE_SETS",
              "PONDS_HARVEST",
              "USER_LABS",
              "RESOURCE_VENDORS",
              "USER_RESOURCES",
              "STOCK_FEEDS",
              "STOCK_MEDICINES",
              "PONDS_AQUASIMS"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Client App version",
          field: "client_app_version",
          colId: "client_app_version",
          sortable: false,
          filter: true,
          required: false,
          visible: this.isIntersect(
            [
              "SCHEDULES",
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              "POND_DEVICES", // "POND_PM_MAP",
              "ABW",
              // "PROFILE_SETTINGS",
              "PREFERENCES_SETTINGS",
              "USER_SETTINGS",
              "POND_SETTINGS",
              "POND_ALERT",
              "USER_LOCATIONS",
              "MANUAL_POND_FEEDINGS",
              "PONDS_LAB_TEST",
              "PONDS_MEDICINES",
              "POND_SCHEDULE_SETS",
              "PONDS_HARVEST",
              "USER_LABS",
              "RESOURCE_VENDORS",
              "USER_RESOURCES",
              "STOCK_FEEDS",
              "STOCK_MEDICINES",
              "PONDS_AQUASIMS"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Updated Date",
          field: "date",
          colId: "date",
          sortable: true,
          filter: "agGridDateTimeCompareFilter",
          required: true,
          visible: this.isIntersect(
            [
              "SCHEDULES",
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              "POND_DEVICES", // "POND_PM_MAP",
              // "POND_ST_MAP",
              // "POND_PG_MAP",
              "ABW",
              // "PROFILE_SETTINGS",
              "PREFERENCES_SETTINGS",
              "USER_SETTINGS",
              "POND_SETTINGS",
              "POND_ALERT",
              "USER_LOCATIONS",
              "MANUAL_POND_FEEDINGS",
              "PONDS_LAB_TEST",
              "PONDS_MEDICINES",
              "POND_SCHEDULE_SETS",
              "PONDS_HARVEST",
              "USER_LABS",
              "RESOURCE_VENDORS",
              "USER_RESOURCES",
              "STOCK_FEEDS",
              "STOCK_MEDICINES",
              "PONDS_AQUASIMS"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Action",
          field: "action",
          colId: "action",
          sortable: false,
          filter: true,
          required: true,
          visible: this.isIntersect(
            [
              "SCHEDULES",
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              "POND_DEVICES", // "POND_PM_MAP",
              // "POND_ST_MAP",
              // "POND_PG_MAP",
              "ABW",
              // "PROFILE_SETTINGS",
              "PREFERENCES_SETTINGS",
              "USER_SETTINGS",
              "POND_SETTINGS",
              "POND_ALERT",
              "USER_LOCATIONS",
              "MANUAL_POND_FEEDINGS",
              "PONDS_LAB_TEST",
              "PONDS_MEDICINES",
              "POND_SCHEDULE_SETS",
              "PONDS_HARVEST",
              "USER_LABS",
              "RESOURCE_VENDORS",
              "USER_RESOURCES",
              "STOCK_FEEDS",
              "STOCK_MEDICINES",
              "PONDS_AQUASIMS"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Device Title",
          field: "device_title",
          colId: "device_title",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            [
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              "POND_DEVICES" // "POND_PM_MAP",
              // "POND_ST_MAP",
              // "POND_PG_MAP"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Device Code",
          field: "device_code",
          colId: "device_code",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            [
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              "POND_DEVICES" // "POND_PM_MAP",
              // "POND_ST_MAP",
              // "POND_PG_MAP"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Start Time",
          field: "start_time",
          colId: "start_time",
          sortable: true,
          required: true,
          filter: "agGridDateTimeCompareFilter",
          visible: this.isIntersect(
            ["SCHEDULES", "ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "End Time",
          field: "end_time",
          colId: "end_time",
          sortable: true,
          required: true,
          filter: "agGridDateTimeCompareFilter",
          visible: this.isIntersect(
            ["SCHEDULES", "ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Total Feed",
          field: "feed",
          colId: "feed",
          sortable: true,
          required: true,
          filter: "agNumberColumnFilter",
          visible: this.isIntersect(
            ["SCHEDULES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Dispensed Feed",
          field: "dispensed_feed",
          colId: "dispensed_feed",
          sortable: true,
          required: true,
          filter: "agNumberColumnFilter",
          visible: this.isIntersect(
            ["SCHEDULES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Timeslot Status",
          field: "schedule_status",
          colId: "schedule_status",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["SCHEDULES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Mode",
          field: "schedule_mode",
          colId: "schedule_mode",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["SCHEDULES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "OCF",
          field: "ocf",
          colId: "ocf",
          sortable: true,
          required: true,
          filter: "agNumberColumnFilter",
          visible: this.isIntersect(
            ["SCHEDULES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feed Gap",
          field: "feed_gap",
          colId: "feed_gap",
          sortable: true,
          required: true,
          filter: "agNumberColumnFilter",
          visible: this.isIntersect(
            ["SCHEDULES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "User Type",
          field: "user_type",
          colId: "user_type",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            [
              "SCHEDULES",
              "POND_DEVICES", // "POND_PM_MAP",
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              // "POND_ST_MAP",
              // "POND_PG_MAP",
              "ABW",
              // "PROFILE_SETTINGS",
              "PREFERENCES_SETTINGS",
              "USER_SETTINGS",
              "POND_SETTINGS",
              "POND_ALERT",
              "USER_LOCATIONS",
              "MANUAL_POND_FEEDINGS",
              "PONDS_LAB_TEST",
              "PONDS_MEDICINES",
              "POND_SCHEDULE_SETS",
              "PONDS_HARVEST",
              "USER_LABS",
              "RESOURCE_VENDORS",
              "USER_RESOURCES",
              "STOCK_FEEDS",
              "STOCK_MEDICINES",
              "PONDS_AQUASIMS"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "User Name",
          field: "user_name",
          colId: "user_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            [
              "SCHEDULES",
              "POND_DEVICES", // "POND_PM_MAP",
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              // "POND_ST_MAP",
              // "POND_PG_MAP",
              "ABW",
              // "PROFILE_SETTINGS",
              "PREFERENCES_SETTINGS",
              "USER_SETTINGS",
              "POND_SETTINGS",
              "POND_ALERT",
              "USER_LOCATIONS",
              "MANUAL_POND_FEEDINGS",
              "PONDS_LAB_TEST",
              "PONDS_MEDICINES",
              "POND_SCHEDULE_SETS",
              "PONDS_HARVEST",
              "USER_LABS",
              "RESOURCE_VENDORS",
              "USER_RESOURCES",
              "STOCK_FEEDS",
              "STOCK_MEDICINES",
              "PONDS_AQUASIMS"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "User Email Id",
          field: "user_email",
          colId: "user_email",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            [
              "SCHEDULES",
              "POND_DEVICES", // "POND_PM_MAP",
              "PM_SETTINGS",
              "ST_SETTINGS",
              "PG_SETTINGS",
              "GT_SETTINGS",
              // "POND_ST_MAP",
              // "POND_PG_MAP",
              "ABW",
              // "PROFILE_SETTINGS",
              "PREFERENCES_SETTINGS",
              "USER_SETTINGS",
              "POND_SETTINGS",
              "POND_ALERT",
              "USER_LOCATIONS",
              "MANUAL_POND_FEEDINGS",
              "PONDS_LAB_TEST",
              "PONDS_MEDICINES",
              "POND_SCHEDULE_SETS",
              "PONDS_HARVEST",
              "USER_LABS",
              "RESOURCE_VENDORS",
              "USER_RESOURCES",
              "STOCK_FEEDS",
              "STOCK_MEDICINES",
              "PONDS_AQUASIMS"
            ],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        // {
        //   headerName: "First Name",
        //   field: "profile_first_name",
        //   colId: "profile_first_name",
        //   sortable: true,
        //   filter: true,
        //   required: true,
        //   visible: this.isIntersect(
        //     ["PROFILE_SETTINGS"],
        //     this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
        //   )
        // },
        // {
        //   headerName: "Last Name",
        //   field: "profile_last_name",
        //   colId: "profile_last_name",
        //   sortable: true,
        //   filter: true,
        //   required: true,
        //   visible: this.isIntersect(
        //     ["PROFILE_SETTINGS"],
        //     this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
        //   )
        // },
        {
          headerName: "Device Type",
          field: "device_type",
          colId: "device_type",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_ALERT", "POND_DEVICES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        // {
        //   headerName: "Phone",
        //   field: "profile_phone",
        //   colId: "profile_phone",
        //   sortable: true,
        //   filter: true,
        //   required: true,
        //   visible: this.isIntersect(
        //     ["PROFILE_SETTINGS"],
        //     this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
        //   )
        // },
        {
          headerName: "Error Code",
          field: "error_code",
          colId: "error_code",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["POND_ALERT"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          ),
          valueFormatter: (params) => {
            return errorAlertStringMapping.mapKeyToString(params.value);
          }
        },
        // {
        //   headerName: "Email",
        //   field: "profile_email",
        //   colId: "profile_email",
        //   sortable: true,
        //   filter: true,
        //   required: true,
        //   visible: this.isIntersect(
        //     ["PROFILE_SETTINGS"],
        //     this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
        //   )
        // },
        {
          headerName: "Date Format",
          field: "profile_date_format",
          colId: "profile_date_format",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PREFERENCES_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Time Format",
          field: "profile_time_format",
          colId: "profile_time_format",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PREFERENCES_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Biomass Units",
          field: "profile_biomass_units",
          colId: "profile_biomass_units",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PREFERENCES_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Biomass / Pond Area Units",
          field: "profile_biomass_per_area_units",
          colId: "profile_biomass_per_area_units",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PREFERENCES_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          ),
          valueFormatter: (params) => {
            // `data.biomass_per_area_units.biomass_units/data.biomass_per_area_units.pond_area_units`
            console.log(params);
            if (params.value?.biomass_units && params.value?.pond_area_units) {
              return `${params.value.biomass_units}/${params.value.pond_area_units}`;
            }
            return ``;
          }
        },
        {
          headerName: "Pond Area Units",
          field: "profile_pond_area_units",
          colId: "profile_pond_area_units",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PREFERENCES_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Device Name",
          field: "profile_device_name",
          colId: "profile_device_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PREFERENCES_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feed Gap",
          field: "profile_feed_gap",
          colId: "profile_feed_gap",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PREFERENCES_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Location",
          field: "profile_location",
          colId: "profile_location",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PREFERENCES_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Default Dashboard Tab",
          field: "profile_default_dashboard_tab",
          colId: "profile_default_dashboard_tab",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PREFERENCES_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Sub User Name",
          field: "sub_user_name",
          colId: "sub_user_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Sub User Email",
          field: "sub_user_email",
          colId: "sub_user_email",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "First Name",
          field: "user_registration_first_name",
          colId: "user_registration_first_name",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Last Name",
          field: "user_registration_last_name",
          colId: "user_registration_last_name",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Mobile Number",
          field: "user_registration_phone",
          colId: "user_registration_phone",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Email Address",
          field: "user_registration_email",
          colId: "user_registration_email",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "User Type",
          field: "user_registration_user_type",
          colId: "user_registration_user_type",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Country",
          field: "profile_country",
          colId: "profile_country",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Timezone",
          field: "profile_timezone",
          colId: "profile_timezone",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Status",
          field: "profile_status",
          colId: "profile_status",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Resolved By",
          field: "resolved_by",
          colId: "resolved_by",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["POND_ALERT"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Resolved Date",
          field: "resolved_date",
          colId: "resolved_date",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["POND_ALERT"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
          // valueFormatter: (params) => {
          //   console.log("params.value", params);
          //   return this.formatDateMethod(params.value);
          // }
        },
        {
          headerName: "Verification Status",
          field: "user_registration_verification_status",
          colId: "user_registration_verification_status",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["USER_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
          // valueFormatter: (params) => {
          //   console.log("params.value", params);
          //   return this.formatDateMethod(params.value);
          // }
        },

        {
          headerName: "Resolved Notes",
          field: "resolved_notes",
          colId: "resolved_notes",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["POND_ALERT"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "On Time",
          field: "on_time",
          colId: "on_time",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["SCHEDULES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Off Time",
          field: "off_time",
          colId: "off_time",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["SCHEDULES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Gateway Id",
          field: "gateway_id",
          colId: "gateway_id",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["SCHEDULES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Alias Name",
          field: "alias_name",
          colId: "alias_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PM_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "kg Dispense Time",
          field: "kg_dispense_time",
          colId: "kg_dispense_time",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PM_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Mode",
          field: "mode",
          colId: "mode",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PM_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Hopper Type",
          field: "hopper_type",
          colId: "hopper_type",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PM_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Device Ui Edit",
          field: "device_ui_edit",
          colId: "device_ui_edit",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PM_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Alias Name",
          field: "alias_name",
          colId: "alias_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Pause Start",
          field: "pause_start",
          colId: "pause_start",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Pause End",
          field: "pause_end",
          colId: "pause_end",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feed Limit",
          field: "feed_limit",
          colId: "feed_limit",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feeder 1",
          field: "feeder_1",
          colId: "feeder_1",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feeder 2",
          field: "feeder_2",
          colId: "feeder_2",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feeder 3",
          field: "feeder_3",
          colId: "feeder_3",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Status",
          field: "pmStatus",
          colId: "pmStatus",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feeding Level",
          field: "feeder_level",
          colId: "feeder_level",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ST_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        // {
        //   headerName: "External IP Address",
        //   field: "external_ip_address",
        //   colId: "external_ip_address",
        //   sortable: false,
        //   filter: true,
        //   required: true,
        //   visible: this.isIntersect(
        //     ["ST_SETTINGS"],
        //     this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
        //   )
        // },
        // {
        //   headerName: "Average feed utilization",
        //   field: "avg_feed_utilization",
        //   colId: "avg_feed_utilization",
        //   sortable: false,
        //   filter: true,
        //   required: true,
        //   visible: this.isIntersect(
        //     ["ST_SETTINGS"],
        //     this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
        //   )
        // },
        {
          headerName: "Low DO",
          field: "do_config",
          colId: "do_config",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PG_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "DO Critical",
          field: "do_critical",
          colId: "do_critical",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PG_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Temp Range(From)",
          field: "temp_config",
          colId: "temp_config",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PG_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Temp Range(To)",
          field: "temp_config_to",
          colId: "temp_config_to",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PG_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Alias Name",
          field: "alias_name",
          colId: "alias_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PG_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Alias Name",
          field: "alias_name",
          colId: "alias_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["GT_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Allow Other Location Devices",
          field: "allow_all_devices",
          colId: "allow_all_devices",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["GT_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Pond Name",
          field: "pond_name",
          colId: "pond_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_DEVICES", "MANUAL_POND_FEEDINGS",
              "PONDS_LAB_TEST",
              "PONDS_MEDICINES",
              "POND_SCHEDULE_SETS",
              "PONDS_HARVEST", "PONDS_AQUASIMS", "ABW", "POND_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Location",
          field: "location",
          colId: "location",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["GT_SETTINGS", "USER_LOCATIONS", "POND_DEVICES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "ABW",
          field: "abw",
          colId: "abw",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ABW"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Survival Percentage",
          field: "survival_percentage",
          colId: "survival_percentage",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["ABW"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Pond Size",
          field: "size",
          colId: "size",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Units",
          field: "units",
          colId: "units",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Stocking Date",
          field: "cultivation_date",
          colId: "cultivation_date",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "PL Stocked",
          field: "post_larva_stocked",
          colId: "post_larva_stocked",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Initial ABW",
          field: "initial_abw_id",
          colId: "initial_abw_id",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Status",
          field: "status",
          colId: "status",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SETTINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feed Name",
          field: "name",
          colId: "name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["MANUAL_POND_FEEDINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Dispensed Feed",
          field: "feed_dispensed",
          colId: "feed_dispensed",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["MANUAL_POND_FEEDINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feed Added Date",
          field: "feedDate",
          colId: "feedDate",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["MANUAL_POND_FEEDINGS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "HOC",
          field: "harvestDate",
          colId: "harvestDate",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_HARVEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "ABW",
          field: "abw",
          colId: "abw",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_HARVEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Biomass",
          field: "biomass",
          colId: "biomass",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_HARVEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Biomass Units",
          field: "biomass_units",
          colId: "biomass_units",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_HARVEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Cumulative Feed",
          field: "cumulative_feed",
          colId: "cumulative_feed",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_HARVEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Status",
          field: "type",
          colId: "type",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_HARVEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "FCR",
          field: "fcr",
          colId: "fcr",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_HARVEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Variety",
          field: "type",
          colId: "type",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Resource",
          field: "resource",
          colId: "resource",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Quantity",
          field: "quantity",
          colId: "quantity",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Note",
          field: "notes",
          colId: "notes",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Lab Name",
          field: "lab_id",
          colId: "lab_id",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST", 'USER_LABS'],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "pH",
          field: "ph",
          colId: "ph",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Salinity(PPT)",
          field: "salinity",
          colId: "salinity",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "DO",
          field: "do",
          colId: "do",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Temp",
          field: "temperature",
          colId: "temperature",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "CO3",
          field: "co3",
          colId: "co3",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "HCO3",
          field: "hco3",
          colId: "hco3",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Hardness(PPM)",
          field: "hardness",
          colId: "hardness",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Ammonia(PPM)",
          field: "ammonia",
          colId: "ammonia",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Ammonium(PPM)",
          field: "ammonium",
          colId: "ammonium",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "TAN",
          field: "tan",
          colId: "tan",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Iron(PPM)",
          field: "iron",
          colId: "iron",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Green Colonies",
          field: "green_colonies",
          colId: "green_colonies",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Yellow Colonies",
          field: "yellow_colonies",
          colId: "yellow_colonies",
          sortable: true,
          filter: true,
          required: false,
          visible: this.isIntersect(
            ["PONDS_LAB_TEST"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Set Name",
          field: "set_name",
          colId: "set_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SCHEDULE_SETS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feed Ratio %",
          field: "feed_percentage",
          colId: "feed_percentage",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SCHEDULE_SETS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "	Start Time",
          field: "s_time",
          colId: "s_time",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SCHEDULE_SETS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "	End Time",
          field: "e_time",
          colId: "e_time",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SCHEDULE_SETS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Assigned Status",
          field: "pond_status",
          colId: "pond_status",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SCHEDULE_SETS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Pond Assigned Feed Type",
          field: "pond_feed_type",
          colId: "pond_feed_type",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["POND_SCHEDULE_SETS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Lab Phone",
          field: "phone",
          colId: "phone",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_LABS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Technician Name",
          field: "technician_name",
          colId: "technician_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_LABS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Technician Phone",
          field: "technician_phone",
          colId: "technician_phone",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_LABS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Lab Address",
          field: "address",
          colId: "address",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_LABS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Vendor Name",
          field: "vendor_name",
          colId: "vendor_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["RESOURCE_VENDORS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Mobile Number",
          field: "vendor_mobile",
          colId: "vendor_mobile",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["RESOURCE_VENDORS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Resource Name",
          field: "resource_name",
          colId: "resource_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_RESOURCES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Type",
          field: "resource_type",
          colId: "resource_type",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_RESOURCES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "1Kg DIspence Time",
          field: "kg_dispensed_time",
          colId: "kg_dispensed_time",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_RESOURCES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Units",
          field: "units",
          colId: "units",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["USER_RESOURCES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Feed Name",
          field: "feed_name",
          colId: "feed_name",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["STOCK_FEEDS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "No. of Bags",
          field: "number_of_units",
          colId: "number_of_units",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["STOCK_FEEDS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Medicine Name",
          field: "resource_id",
          colId: "resource_id",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["STOCK_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "No. of Units",
          field: "numberOfUnits",
          colId: "numberOfUnits",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["STOCK_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Bag Weight",
          field: "unit_quantity",
          colId: "unit_quantity",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["STOCK_FEEDS", "STOCK_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Vendor Name",
          field: "vendor_id",
          colId: "vendor_id",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["STOCK_FEEDS", "STOCK_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "purchased Date",
          field: "purchaseDate",
          colId: "purchaseDate",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["STOCK_FEEDS", "STOCK_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Last Updated Date",
          field: "last_updated_at",
          colId: "last_updated_at",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["STOCK_FEEDS", "STOCK_MEDICINES"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Aquasim",
          field: "aqua_sim_pond_id",
          colId: "aqua_sim_pond_id",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_AQUASIMS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
        {
          headerName: "Aqua Schedules ",
          field: "aqua_sim_auto_schedules",
          colId: "aqua_sim_auto_schedules",
          sortable: true,
          filter: true,
          required: true,
          visible: this.isIntersect(
            ["PONDS_AQUASIMS"],
            this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")
          )
        },
      ].filter((x) => x.visible());
    },
    tableData() {
      return this.getActivityLogDataBySelectedFilters;
    },
    frameworkComponents: function() {
      return {
        agGridDateTimeCompareFilter: agGridDateTimeCompareFilter
      };
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  methods: {
    isIntersect(arr1 = [], value) {
      return () => arr1.includes(value);
    },
    formatDateMethod(UtcDate) {
      return (
        UtcDate &&
        this.adm__dateUtilsLib.getFormatByTimeZoneOnUTC(
          UtcDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          this.getUserTimeZoneString,
          this.getLocaleObj
        )
      );
    },
    handleChangeColumnSelection(selectedColumnsId) {
      const allColumns = this.columnsArr.map((col, id) => {
        return col.colId;
      });
      const columnsToHide = allColumns.filter(
        (x) => !new Set(selectedColumnsId).has(x)
      );
      this.$nextTick(() => {
        if (
          this.$refs.agGridActivityLog &&
          this.gridColumnApi &&
          selectedColumnsId &&
          selectedColumnsId.length > 0
        ) {
          this.gridColumnApi.setColumnsVisible(selectedColumnsId, true);
          this.gridColumnApi.setColumnsVisible(columnsToHide, false);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.log-table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  &::v-deep .ag-theme-alpine {
    flex-grow: 1;
    height: 100%;
  }
  &::v-deep .ag-grid-datetime-compare-filter {
    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 182px;
    }
  }
}
</style>
