<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: customerPondMotherAG.vue
Description:This file contains the details the customer pondmothers with column wise filters
-->
<template>
  <div>
    <ag-grid-vue
      v-loading="loadingDevices"
      style="width: 100%; height: calc(100vh - 215px)"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :pagination="true"
      :rowData="deviceData"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { mapActions } from "vuex";
import ShrimpTalk from "@/model/shrimptalk";
export default {
  props: ["deviceDetails", "loadingDevices"],
  data () {
    return {
      columnDefs: null,
      rowData: null
    };
  },
  components: {
    AgGridVue
  },
  computed: {
    deviceData: function () {
      return this.$lodash.cloneDeep(this.deviceDetails).map((x) => {
        ["v1_parsing", "v2_parsing"].forEach((key) => {
          if (x.settings && x.settings[key]) {
            x.settings[key] = 1;
          } else {
            if (!x.settings) {
              x.settings = {};
            }
            x.settings[key] = 0;
          }
        });
        return x;
      });
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails"
    }),
    handleStCodeConvertion (params) {
      return params.data.shrimp_talk_code
        ? `${ShrimpTalk.v1Title(params.data.shrimp_talk_code)} (${
            params.data.shrimp_talk_code
          })`
        : "NO_ST";
    },
    handleGatewayCodeConvertion (params) {
      return params.data.gateway_code || "NO_GATEWAY";
    }
  },
  async beforeMount () {
    this.columnDefs = [
      {
        headerName: "PondMother Code",
        field: "code",
        sortable: true,
        filter: true
      },
      { headerName: "Alias", field: "title", sortable: true, filter: true },
      { headerName: "V1_id", field: "v1_id", sortable: true, filter: true },
      { headerName: "V2_id", field: "_id", sortable: true, filter: true },

      {
        headerName: "Software Version",
        field: "settings.software_version",
        sortable: true,
        filter: true
      },
      {
        headerName: "Hardware Version",
        field: "settings.hardware_version",
        sortable: true,
        filter: true
      },
      {
        headerName: "Last Communication",
        field: "utc_last_communicated_at",
        sortable: true,
        filter: true
      },
      {
        headerName: "Gateway Code",
        field: "gateway_code",
        sortable: true,
        filter: true,
        valueFormatter: this.handleGatewayCodeConvertion
      },
      {
        headerName: "Shrimptalk Code",
        field: "settings.shrimp_talk_code",
        sortable: true,
        filter: true,
        valueFormatter: this.handleStCodeConvertion
      }
    ];
    this.rowData = this.deviceDetails;
  }
};
</script>
