<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: CusomerDetailsTable.vue
Description: This file contains the table component that display customer details who are allotted to the accout manager in account manager site
-->

<template>
  <div
    class="customer-details-table"
    v-loading="loading"
    element-loading-background="white"
  >
    <div class="page_loading" v-if="loading"></div>
    <layout-skretting-technician-card
      toolbar-layout="search"
      search-property="user_name"
      placeHolderForSearch="Search_username"
      @loadData="handleSearch"
      v-show="!loading"
    >
      <template v-slot:heading>
        <div style="display: flex; align-items: center">
          <img src="@/assets/dealer/peoplegroup.svg" atl="down arrow" />
          <p style="margin-left: 20px">
            {{ `${$t("all")} ${$t("DL_customers")}` }}
          </p>
        </div>
      </template>

      <template slot="table">
        <defaultTable
          v-loading="loading"
          element-loading-background="white"
          :tableData="tableData"
          :columns="columnsObject"
          :total="total"
          :tableProps="elTableProps"
          placeHolderForSearch="Search_username"
          :addPadingToSearch="true"
          @reload="handleLoadData"
          @cleared="handleLoadData"
        >
          <template v-slot:first_name_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:email_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:tags_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:actions_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:country_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:phone_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>

          <template v-slot:first_name="{ row }">
            {{ row.data.first_name }}
            {{ row.data.last_name }}
          </template>
          <template v-slot:actions="{ row }">
            <el-button-group>
              <er-button
                size="mini"
                btnType="download"
                :title="$t('Harvest_log_in')"
                :showIcon="false"
                :disabled="isReadOnly"
                :showLabel="true"
                @click="handleLoginAsUser($event, row.data._id)"
              >
                {{ $t("login_as_user") }}
              </er-button>
            </el-button-group>
          </template>
        </defaultTable>
      </template>
    </layout-skretting-technician-card>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import defaultTable from "./defaultTable";
import layoutSkrettingTechnicianCard from "./layoutSkrettingTechnicianCard";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin, notificationMixin],

  data: function () {
    return {
      tableData: [],
      selectedUsers: [],
      total: 0,
      dialogVisible: false,
      loading: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      queryParams: {},
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: {
        default: ""
      }
    };
  },
  components: {
    defaultTable,
    layoutSkrettingTechnicianCard
  },
   methods: {
    ...mapActions("skrettingTechnician", {
      fetchAlreadyAssingedUsersFromSubuser: "fetchAlreadyAssingedUsersFromSubuser",
      LoginAsUser: "LoginAsUser",
      fetchUserProfile: "fetchUserProfile"
    }),
      ...mapActions("user", {
      fetchUserLocations: "fetchUserLocations",
    }),
     async initComponent() {
      try {
        this.loading = true;
        // await this.$store.dispatch("skrettingTechnician/fetchUserProfile");
        await this.fetchUserProfile();
        await this.handleLoadData();
      } catch (err) {
        console.log(err);
        // this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleSearch(query) {
      console.log(query);
      if (query?.user_name && query.user_name !== '') {
        this.loading = true;
        this.tableData = this.getSkrettingTechnicianUsers;
        this.tableData.map((item, i) => {
          const { first_name, last_name } = item;
          item.full_name = first_name + ' ' + last_name;
        });
        const filteredData = this.tableData.filter((item, i) => {
          return item.full_name.toLowerCase().includes(query.user_name.toLowerCase());
        });
        this.tableData = [...filteredData];
        this.total = this.tableData?.length;
        this.loading = false;
      } else {
        this.loading = true;
        this.tableData = this.getSkrettingTechnicianUsers;
        this.total = this.tableData?.length;
        this.loading = false;
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        // setTimeout(async () => {
        if (this.getSkrettingTechnicianProfile._id !== undefined) {
          await this.fetchAlreadyAssingedUsersFromSubuser({
            subuser_id: this.getUserId
          });

          this.tableData = await this.getSkrettingTechnicianUsers;
          this.tableData.map((item, i) => {
            if (item?.country !== undefined) {
              item.country.name = item.country.name.charAt(0).toUpperCase() + item.country.name.slice(1);
            }
          });
          this.total = this.tableData?.length;
          this.loading = false;
        }
        // }, 2000);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleAddDealerClick() {
      this.dialogVisible = true;
    },
    async handleLoginAsUser(event, userId) {
      const loading = this.$loading({ fullscreen: true });
      try {
        await this.LoginAsUser(userId);
        this.$router.push(
          "/user/",
          () => {
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("Welcome_to_pondlogs"),
              duration: 5000,
              type: "success"
            });
            loading.close();
          },
          () => {
            this.$notify({
              title: this.$t("failed"),
              message: this.$t("Something_went_wrong_try_again"),
              duration: 5000,
              type: "error"
            });
            loading.close();
          }
        );
      } catch (err) {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Something_went_wrong"),
          duration: 5000,
          type: "error"
        });
        loading.close();
      }
    }
  },
 async mounted() {
    await this.initComponent();
  },
  computed: {
    ...mapGetters("skrettingTechnician", {
      getSkrettingTechnicianUsers: "getSkrettingTechnicianUsers",
      getSkrettingTechnicianProfile: "getSkrettingTechnicianProfile"
    }),
    // ...mapGetters("user", {
    //   getUserProfile: "getUserProfile"
    // }),
    getskrettingTechnician() {
      return this.$store.getters["skrettingTechnician/getSkrettingTechnicianProfile"];
    },
     getUserId() {
       console.log('getSkrettingTechnicianProfile', this.getSkrettingTechnicianProfile);
      return this.getSkrettingTechnicianProfile._id;
    },
        // ...mapActions("accountManager", {
    //   getSkrettingTechniciansUsers: ""
    // }),
    elTableProps() {
      return {
        defaultSort: {
          prop: "first_name",
          order: "ascending"
        }
      };
    },

    columnsObject: function () {
      return [
        {
          label: "accountmanager.Comn_name",
          prop: "first_name",
          name: "first_name",
          required: true,
          sortable: true
        },
        {
          label: "Comn_email_address",
          prop: "email",
          name: "email",
          required: true,
          sortable: true
        },

        {
          prop: "country.name",
          label: "accountmanager.Comn_country",
          name: "country",
          required: true,
          sortable: true
        },

        {
          prop: "phone",
          label: "accountmanager.Usrs_phone",
          name: "phone",
          required: true,
          sortable: true
        },
        {
          prop: "tags",
          label: "Usrs_tags",
          name: "tags",
          required: true,
          sortable: true
        },

        {
          label: "accountmanager.Comn_actions",
          name: "Actions",
          prop: "actions",
          required: true
        }
      ];
    }
  }
};
</script>

<style lang="scss">
.customer-details-table {
  .page_loading {
    @include responsiveProperty(height, 100vh, 100vh, 100vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
  .settings-table .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 80vh, 70vh, 76vh);
  }
}
</style>
