<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: clientsIndividualViewSA.vue
Description: This page has components related to displaying all the clients available and display the data related to selected client
-->
<template>
  <layout class="clients-individual-view-sa">
    <template slot="deviceListMenu">
      <item-list-side-bar
        v-loading="loading"
        element-loading-background="white"
        :item-list="clientsList"
        :init-item-id="client_id"
        item-type="clients"
        showProperty="title"
        :totalItemsCount="totalItemsCount"
        :dataFetchMethod="loadClientsData"
        @loadData="loadClientsData"
        @cleared="loadClientsData"
        @itemClicked="handleClientSelected"
      ></item-list-side-bar>
    </template>
    <er-dialog
      :title="clientActionDialogTitle"
      :visible.sync="clientActionDialogVisible"
      :append-to-body="true"
      width="30%"
      @open="handleAddClientDialogOpen"
      custom-class="client-individual-view-sa-dialog"
    >
      <ValidationObserver ref="dialogListForm">
        <el-form :model="selectedClient">
          <ValidationProvider
            name="Client Name"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item label="Client Name" :error="errors[0]">
              <el-input
                v-model="selectedClient.title"
                :disabled="
                  ['ADD_CLIENT_USERS', 'DELETE_CLIENT_USERS'].includes(action)
                "
              ></el-input>
            </el-form-item>
          </ValidationProvider>

          <ValidationProvider name="Users" rules="required" v-slot="{ errors }">
            <el-form-item
              label="Users"
              v-if="
                ['ADD_CLIENT_USERS', 'DELETE_CLIENT_USERS'].includes(action)
              "
              :error="errors[0]"
            >
              <er-select
                :placeholder="$t('Comn_search')"
                v-model="selectedClient.users"
                :loading="userListLoading"
                multiple
                filterable
                collapse-tags
                remote
                :remote-method="fetchUsersToAssign"
                value-key="_id"
              >
                <el-option
                  v-for="item in usersList"
                  :key="item._id"
                  :label="`${item.first_name} ${item.last_name}`"
                  :value="item"
                >
                  <p>
                    {{ item.last_name }} {{ item.first_name }}
                    <span class="user-list-email-field"
                      >({{ item.email }})
                    </span>
                  </p>
                </el-option>
              </er-select>
            </el-form-item>
          </ValidationProvider>
        </el-form>
      </ValidationObserver>
      <template slot="footer">
        <er-button
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="saveLoading"
          @click="handleSaveClient"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          :loading="saveLoading"
          @click="closeAddClientDialog"
        ></er-button>
      </template>
    </er-dialog>
    <settings-table
      v-loading="loading"
      element-loading-background="white"
      :tableData="getUsersOfClient"
      :columns="columnsObject"
      :total="totalUsers"
      :key="`${client_id}_${getUsersCount}`"
      placeHolderForSearch="Search_username"
      search-property="user_name"
      :addPadingToSearch="true"
      @loadData="callStoreOnClientUsersData"
      @reload="callStoreOnClientUsersData"
      @cleared="callStoreOnClientUsersData"
    >
      <template v-slot:name="{ row }">
        {{ row.data.first_name }} {{ row.data.last_name }}
      </template>
      <template slot="table-toolbar-left-slot">
        <er-button size="mini" @click="handleAddUsersToClient()">
          <span class="material-icons-round"> person_add </span>
        </er-button>
        <er-button
          size="mini"
          @click="handleDeleteUsersToClient()"
          :disabled="isAvailableToDelete"
        >
          <span class="material-icons-round"> person_remove </span>
        </er-button>
      </template>
    </settings-table>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import layout from "@/components/superadmin/shared/layout";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import settingsTable from "@/components/base/settingsTable";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin],
  props: ["client_id"],
  components: {
    layout,
    settingsTable,
    itemListSideBar
  },
  data: function () {
    return {
      loading: false,
      gateway_data: "",
      saveLoading: false,
      clientActionDialogTitle: "",
      tableData: [],
      totalUsers: 10,
      selectedClient: {},
      dialogBoxLoading: false,
      totalItemsCount: 100,
      usersList: [],
      userListLoading: false,
      userQuery: {},
      userSearchQuery: {},
      action: "ADD_CLIENT",
      clientActionDialogVisible: false
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getUsers: "getUsers",
      getPondsObj: "getPondsObj",
      getClients: "getClients",
      getClientsObj: "getClientsObj",
      getUsersOfClient: "getUsersOfClient"
    }),
    getClientDetails: function () {
      return this.getClientsObj[this.client_id];
    },
    getUsersCount: function () {
      return (this.getClientDetails || { users: [] }).users.length;
    },
    isAvailableToDelete: function () {
      return (this.getClientDetails || { users: [] }).users.length === 0;
    },
    columnsObject: function () {
      return [
        {
          label: "Name",
          prop: "first_name",
          required: true,
          sortable: true
        },
        {
          prop: "phone",
          label: "Mobile Number",
          required: true,
          sortable: true
        }
      ];
    },
    deviceDetails() {
      // return this.getGatewaysObj[this.gateway_id];
      const deviceDetails = { ...this.gateway_data };
      deviceDetails.device_type = "gateway";
      return deviceDetails;
    },
    customerDetails() {
      if (this.deviceDetails) {
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: ""
        };
      }
    },
    clientsList() {
      return this.getClients;
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllClients: "fetchAllClients",
      fetchADeviceData: "fetchADeviceData",
      fetchAllUsers: "fetchAllUsers",
      deleteDevicesToUser: "deleteDevicesToUser",
      fetchUsersOfClient: "fetchUsersOfClient",
      addUsersToClient: "addUsersToClient",
      deleteUsersToClient: "deleteUsersToClient",
      createClient: "createClient",
      updateClient: "updateClient"
    }),
    getNewClient() {
      return {
        title: undefined,
        users: []
      };
    },
    async fetchUsersToAssign(queryString) {
      if (queryString === "") return;
      try {
        this.userListLoading = true;
        this.userSearchQuery.user_name = queryString;
        if (["ADD_CLIENT_USERS"].includes(this.action)) {
          this.userSearchQuery.excluded_client_ids = [this.selectedClient._id];
          delete this.userSearchQuery.included_client_ids;
        } else if (["DELETE_CLIENT_USERS"].includes(this.action)) {
          this.userSearchQuery.included_client_ids = [this.selectedClient._id];
          delete this.userSearchQuery.excluded_client_ids;
        }
        await this.fetchAllUsers({
          infinite_scroll: false,
          params: this.userSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.userListLoading = false;
        this.usersList = this.getUsers;
      }
    },
    handleAddUsersToClient() {
      this.action = "ADD_CLIENT_USERS";
      this.clientActionDialogTitle = "Add Client Users";
      const newClient = this.getNewClient();
      newClient.title = this.getClientDetails.title;
      newClient._id = this.getClientDetails._id;
      this.selectedClient = newClient;
      this.clientActionDialogVisible = true;
      this.searchMethod = this.fetchUsersToAssign;
    },
    closeAddClientDialog() {
      this.clientActionDialogVisible = false;
      this.action = "";
    },
    handleAddClientDialogOpen() {
      delete this.userQuery.user_name;
      this.usersList = [];
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
    },
    handleDeleteUsersToClient() {
      this.action = "DELETE_CLIENT_USERS";
      this.clientActionDialogTitle = "Delete Client Users";
      const newClient = this.getNewClient();
      newClient.title = this.getClientDetails.title;
      newClient._id = this.getClientDetails._id;
      this.selectedClient = newClient;
      this.clientActionDialogVisible = true;
      this.searchMethod = this.fetchUsersToAssign;
    },
    async handleSaveClient() {
      const actionToMessage = {
        ADD_CLIENT: {
          success: `Created ${this.selectedClient.title} Successfully`,
          fail: `Failed to create ${this.selectedClient.title}`
        },
        EDIT_CLIENT: {
          success: `Updated ${this.selectedClient.title} Successfully`,
          fail: `Failed to update ${this.selectedClient.title}`
        },
        ADD_CLIENT_USERS: {
          success: `Added users to ${this.selectedClient.title} Successfully`,
          fail: `Failed to add users to ${this.selectedClient.title}`
        },
        DELETE_CLIENT_USERS: {
          success: `Deleted users from ${this.selectedClient.title} Successfully`,
          fail: `Failed to delete users from  ${this.selectedClient.title}`
        }
      };
      try {
        const response = await this.$refs.dialogListForm.validate();
        if (!response) return;
        this.saveLoading = true;
        const actionToStoreAction = {
          ADD_CLIENT: this.createClient,
          EDIT_CLIENT: this.updateClient,
          DELETE_CLIENT_USERS: this.deleteUsersToClient,
          ADD_CLIENT_USERS: this.addUsersToClient
        };
        let payload;
        if (["ADD_CLIENT_USERS", "DELETE_CLIENT_USERS"].includes(this.action)) {
          payload = {
            client_id: this.selectedClient._id,
            user_ids: this.selectedClient.users.map((x) => x._id)
          };
        } else if (this.action === "ADD_CLIENT") {
          payload = {
            title: this.selectedClient.title
          };
        } else {
          payload = {
            client_id: this.selectedClient._id,
            title: this.selectedClient.title
          };
        }
        await actionToStoreAction[this.action](payload);
        this.$notify({
          type: "success",
          title: "Success",
          message: actionToMessage[this.action].success
        });
        this.closeAddClientDialog();
        await this.initComponent();
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Failed",
          message: actionToMessage[this.action].fail
        });
        this.ehm__errorMessages(err, true);
      } finally {
        this.saveLoading = false;
      }
    },
    async handleRemoveCustomer() {
      try {
        const response = await this.$refs.dialogListForm.validate();
        if (!response) return;
        this.loading = true;
        await this.deleteDevicesToUser({
          user_id: this.customerDetails._id,
          payload: [this.gateway_id],
          device_type: "GATEWAY"
        });
        this.$notify({
          type: "success",
          message: "Device is unassigned from the customer successfully",
          title: "Success"
        });
        await this.initComponent();
      } catch (err) {
        this.$notify({
          type: "error",
          message: "Failed to unassign customer",
          title: "Failed"
        });
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async initComponent() {
      try {
        // console.log(parameter);
        this.loading = true;
        // { get_all: true }
        await this.fetchAllClients({
          infinite_scroll: false,
          params: {
            page: 1,
            limit: 19,
            order_by: "first_name",
            order_type: "asc"
          }
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadClientsData({ query, infinite_scroll = false }) {
      try {
        const response = await this.fetchAllClients({
          infinite_scroll: infinite_scroll,
          params: query
        });
        this.totalItemsCount = response.total;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async callStoreOnClientUsersData(query) {
      try {
        this.loading = true;
        this.userQuery = query;
        this.userQuery.included_client_ids = [this.client_id];
        const response = await this.fetchUsersOfClient({
          infinite_scroll: false,
          params: this.userQuery
        });
        this.totalUsers = response.total;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleClientSelected(client_id) {
      try {
        this.loading = true;
        this.$router.replace(client_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.clients-individual-view-sa {
  .settings-table .settings-table-scroll {
    position: relative;
    height: 80vh;
  }
}
.client-individual-view-sa-dialog {
  border-radius: 10px;
  .user-list-email-field {
    text-transform: lowercase;
  }
}
</style>
