<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: gatewayDeviceNetworks
Description:This file contains the list of the  Available Networks
-->
<template>
  <div
    class="gateway-device-networks"
    v-loading="loading"
    element-loading-background="white"
  >
    <basic-customer-details
      :device-details="deviceDetails"
      :user-details="customerDetails"
      :showCustomerDetails="false"
      :allowAddCustomer="false"
      :allowCustomerLogin="false"
    >
      <template slot="moreDetails">
        <center>
          <el-row class="details__heading">Name</el-row>
          <el-row class="details__heading__value">
            {{
              this.customerDetails.first_name
                ? this.customerDetails.first_name
                : "--"
            }}
            {{
              this.customerDetails.last_name
                ? this.customerDetails.last_name
                : "--"
            }}
          </el-row>
        </center>
      </template>
      <template slot="moreDetails">
        <center>
          <el-row class="details__heading">Email Address</el-row>
          <el-row class="details__heading__value">
            {{ this.customerDetails.email ? this.customerDetails.email : "--" }}
          </el-row>
        </center>
      </template>
      <template slot="moreDetails">
        <center>
          <el-row class="details__heading">Mobile Number</el-row>
          <el-row class="details__heading__value">
            {{ this.customerDetails.phone ? this.customerDetails.phone : "--" }}
          </el-row>
        </center>
      </template>
      <template slot="moreDetails">
        <center>
          <el-row class="details__heading">Location</el-row>
          <el-row class="details__heading__value">
            {{
              this.customerDetails.country
                ? this.customerDetails.country.name
                : "--"
            }}
          </el-row>
        </center>
      </template>

      <template slot="moreDetails">
        <center>
          <el-row class="details__heading">Latitude - Longitude</el-row>
          <el-row class="details__heading__value">
            {{
              typeof this.deviceDetails.settings.latitude !== "undefined"
                ? this.deviceDetails.settings.latitude +
                  "-" +
                  this.deviceDetails.settings.longitude
                : "--"
            }}
          </el-row>
        </center>
      </template>
    </basic-customer-details>
    <!-- <el-scrollbar
      class="gateway-device-networks-scroll"
      viewClass="gateway-device-networks-scroll-view"
      wrapClass="gateway-device-networks-scroll-wrap"
    > -->
      <!-- <el-card shadow="never">
        <template slot="header">
          <h3>Customer Details</h3>
        </template>
        <el-table class="table--600" size="mini" :data="deviceDetails.user_data">
          <el-table-column prop="first_name" label="Name">
            <template slot-scope="scope">
              <el-link :href="`/admin/customers/${scope.row._id}`">{{fullName(scope.row)}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="email" label="Email"></el-table-column>
          <el-table-column prop="phone" label="Phone"></el-table-column>
        </el-table>
      </el-card>&nbsp; -->

      <el-tabs v-model="activeTab">
        <el-tab-pane label="Sensor Logs" name="SensorLogs">
          <gateway-voltage
            v-loading="loading"
            element-loading-background="white"
            @changedDateQuery="handleChangedDateQuery"
          ></gateway-voltage>
        </el-tab-pane>
        <el-tab-pane label="Networks" name="Networks">
          <el-row type="flex">
            <el-col :span="11">
              <small-table
                v-loading="loading"
                element-loading-background="white"
                :tableData="wifi_table_data"
                search-on-property="wifi_network"
                :columns="wifi_table_columns"
                :addPadingToSearch="true"
                tableName="Available Networks"
              ></small-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Communicatation Stats" name="CommunicatationStats" class="CommunicatationStats-css">
          <el-row type="flex">
            <el-col :span="11">
              <small-table-server-side
                v-loading="loading"
                element-loading-background="white"
                tableName="PondMother Communication Stats"
                :tableData="pond_mother_data"
                :total="pond_mother_total"
                :columns="pmStatsColumn"
                :addPadingToSearch="true"
                search-on-property="code"
                device-type="POND_MOTHER"
                :tableProps="smallTableServerSideTableProps"
                @loadData="getGatewayCommunicationStats"
                @reloadData="getGatewayCommunicationStats"
              >
                <template v-slot:code="{ row }">
                  <el-link
                    @click.prevent="handlePmClick($event, row.data.code)"
                    >{{ row.data.code }}</el-link
                  >
                  <!-- <el-button type="text" @click="handlePmClick($event,row.data.code)">{{row.data.code}}</el-button> -->
                </template>
                <template v-slot:packet_type="{ row }">
                  {{ handleCommunicationType(row.data.communication_type) }}
                </template>
              </small-table-server-side>
            </el-col>
            <el-col :span="2"> </el-col>
            <el-col :span="11">
              <small-table-server-side
                v-loading="loading"
                element-loading-background="white"
                tableName="PondGuard Communication Stats"
                :tableData="pond_guard_data"
                :total="pond_guard_total"
                :columns="statsColumn"
                :addPadingToSearch="true"
                search-on-property="code"
                device-type="POND_GUARD"
                :tableProps="smallTableServerSideTableProps"
                @loadData="getGatewayCommunicationStats"
                @reloadData="getGatewayCommunicationStats"
              >
                <template v-slot:code="{ row }">
                  <el-link
                    @click.prevent="handlePgClick($event, row.data.code)"
                    >{{ row.data.code }}</el-link
                  >
                  <!-- <el-button type="text" @click="handlePgClick($event,row.data.code)">{{row.data.code}}</el-button> -->
                </template>
              </small-table-server-side>
            </el-col>
          </el-row>
          <el-divider direction="horizontal"></el-divider>
          <el-row type="flex">
            <el-col :span="11">
              <small-table-server-side
                v-loading="loading"
                element-loading-background="white"
                tableName="ShrimpTalk Communication Stats"
                :tableData="shrimp_talk_data"
                :total="shrimp_talk_total"
                :columns="statsColumn"
                :addPadingToSearch="true"
                search-on-property="code"
                device-type="SHRIMP_TALK"
                :tableProps="smallTableServerSideTableProps"
                @loadData="getGatewayCommunicationStats"
                @reloadData="getGatewayCommunicationStats"
              >
                <template v-slot:code="{ row }">
                  <el-link
                    @click.prevent="handleStClick($event, row.data.code)"
                    >{{ row.data.code }}</el-link
                  >
                  <!-- <el-button type="text" @click="handleStClick($event,row.data.code)">{{row.data.code}}</el-button> -->
                </template>
              </small-table-server-side>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import smallTable from "@/components/superadmin/shared/smallTable";
import smallTableServerSide from "@/components/superadmin/shared/smallTableServerSide";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import gatewayVoltage from "@/components/superadmin/gateway/gatewayVoltage";
import { mapActions } from "vuex";
export default {
  props: ["deviceDetails", "customerDetails"],
  components: {
    smallTable,
    basicCustomerDetails,
    gatewayVoltage,
    smallTableServerSide
  },
  data: function() {
    return {
      loading: false,
      tableData: [],
      emptyStr: "--",
      pond_mother_data: [],
      pond_mother_total: 0,
      pond_guard_data: [],
      pond_guard_total: 0,
      shrimp_talk_data: [],
      shrimp_talk_total: 0,
      smallTableServerSideTableProps: {
        size: "mini",
        border: true,
        height: "210",
        defaultSort: {
          prop: "utc_last_communicated_at",
          order: "descending"
        }
      },
      activeTab: "SensorLogs"
    };
  },
  computed: {
    wifi_table_data: function() {
      const sensorDetails = this.deviceDetails.sensor_values || {};
      const settings = this.deviceDetails.settings || {};

      const wifi_networks = settings.wifi_networks || [];
      const wifi_password = settings.wifi_passwords;
      const wifi_security = settings.wifi_security;
      const wifi_signal = Array.isArray(sensorDetails.wifi_signal_RSSI)
        ? sensorDetails.wifi_signal_RSSI
        : sensorDetails.wifi_signal_RSSI
        ? [sensorDetails.wifi_signal_RSSI]
        : [];

      const tableData = [];
      for (let i = 0; i < wifi_networks.length; i++) {
        tableData.push({
          wifi_network: wifi_networks[i],
          wifi_password: wifi_password[i],
          wifi_security: wifi_security[i],
          wifi_signal: wifi_signal[i] || "--"
        });
      }
      return tableData;
    },
    wifi_table_columns: function() {
      return [
        {
          prop: "wifi_network",
          label: "Wifi Network",
          required: true
        },
        {
          prop: "wifi_password",
          label: "Wifi Password",
          required: true
        },
        {
          prop: "wifi_security",
          label: "Wifi Security",
          required: true
        },
        {
          prop: "wifi_signal",
          label: "Wifi Signal",
          required: true
        }
      ];
    },
    statsColumn() {
      return [
        { prop: "code", label: "Code" },
        { prop: "utc_last_communicated_at", label: "Last Comm", type: "date" },
        { prop: "tx_packets", label: "TX Packets" },
        { prop: "rx_packets", label: "RX Packets" }
      ];
    },
    pmStatsColumn() {
      return [
        { prop: "code", label: "Code" },
        { prop: "utc_last_communicated_at", label: "Last Comm", type: "date" },
        { prop: "tx_packets", label: "TX Packets" },
        { prop: "rx_packets", label: "RX Packets" },
        { prop: "communication_type", label: "Packet Type" }
      ];
    },
    fullName() {
      return function(user) {
        return `${user.first_name} ${user.last_name}`;
      };
    }
  },
  watch: {
    deviceDetails: async function() {
      this.initGatewayStats();
    }
  },
  async mounted() {
    // this.initGatewayStats();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAGatewayLatestSensorData: "fetchAGatewayLatestSensorData",
      fetchGatewayCommunicationStats: "fetchGatewayCommunicationStats",
      fetchAllDeviceDetails: "fetchAllDeviceDetails"
    }),
    handleCommunicationType(data) {
      if (data === 1) {
        return 'BLE';
      } else if (data === 2) {
        return 'WiFi';
      } else {
        return 'Lora';
      }
    },
    async initGatewayStats() {
      const defaultParams = {
        page: 1,
        limit: 5,
        order_by: "utc_last_communicated_at",
        order_type: "desc"
      };
      if (this.deviceDetails) {
        this.loading = true;
        await this.getGatewayCommunicationStats({
          device_type: "POND_MOTHER",
          ...defaultParams
        });

        await this.getGatewayCommunicationStats({
          device_type: "POND_GUARD",
          ...defaultParams
        });

        await this.getGatewayCommunicationStats({
          device_type: "SHRIMP_TALK",
          ...defaultParams
        });
        this.loading = false;
      }
    },
    async handleChangedDateQuery(date) {
      // console.log(date);
      if (this.deviceDetails) {
        this.loading = true;
        await this.fetchAGatewayLatestSensorData({
          gateway_id: this.deviceDetails._id,
          params: date
        });
        this.loading = false;
      }
    },
    async getGatewayCommunicationStats(params) {
      // console.log(this.deviceDetails._id);
      if (this.deviceDetails) {
        this.loading = true;
        const response = await this.fetchGatewayCommunicationStats({
          gateway_id: this.deviceDetails._id,
          params
        });
        if (params.device_type === "POND_MOTHER") {
          this.pond_mother_data = response.gateway_data;
          this.pond_mother_total = response.total;
        } else if (params.device_type === "POND_GUARD") {
          this.pond_guard_data = response.gateway_data;
          this.pond_guard_total = response.total;
        } else if (params.device_type === "SHRIMP_TALK") {
          this.shrimp_talk_data = response.gateway_data;
          this.shrimp_talk_total = response.total;
        }
      }
      this.loading = false;
    },
    async handlePmClick(event, pmCode) {
      this.loading = true;
      const response = await this.fetchAllDeviceDetails({
        device_type: "pond_mother",
        infinite_scroll: false,
        params: { device_code: pmCode }
      });
      this.loading = false;
      this.$router.push(`/admin/pondmothers/${response.pond_mothers[0]._id}`);
    },
    async handleStClick(event, stCode) {
      this.loading = true;
      const response = await this.fetchAllDeviceDetails({
        device_type: "shrimp_talk",
        infinite_scroll: false,
        params: { device_code: stCode }
      });
      this.loading = false;
      this.$router.push(`/admin/shrimptalks/${response.shrimp_talks[0]._id}`);
    },
    async handlePgClick(event, pgCode) {
      this.loading = true;
      const response = await this.fetchAllDeviceDetails({
        device_type: "pond_guard",
        infinite_scroll: false,
        params: { device_code: pgCode }
      });
      this.loading = false;
      this.$router.push(`/admin/pondguards/${response.pond_guards[0]._id}`);
    },
    handleCustomerClick(userId) {
      this.$router.push(`/admin/customers/${userId}`);
    }
  }
};
</script>

<style lang="scss" scoped>
// .el-menu--horizontal {
//   position: relative !important;
//   z-index: 1;
// }
.gateway-device-networks-scroll {
  position: relative;
  height: calc(100vh - 150px);
  padding: 0 10px;
  margin-top: 10px;
  .el-divider--vertical {
    margin-left: 22px;
    height: 100%;
  }
}
.gateway-device-networks-scroll-view {
  padding-right: 10px;
  padding-bottom: 50px;
}
.gateway-device-networks-scroll-wrap {
  height: 100%;
  overflow-x: auto;
}
.gateway-device-networks {
  .CommunicatationStats-css {
    .el-divider--horizontal {
      margin: -26px 0px 10px 0px !important;
    }
  }
}
</style>
<style lang="scss">
.gateway-device-networks-scroll-wrap {
  height: calc(100vh - 155px);
  .el-tabs__header {
    margin-bottom: 5px;
  }
}
</style>
