<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: CustomerDetailsTable.vue
Description: This file is the part of the dealer site which display a table containing customer details.
-->
<template>
  <div
    class="customer-details-table"
    v-loading="loading"
    element-loading-background="white"
  >
    <div class="page_loading" v-if="loading"></div>
    <layout-dealer-card
      toolbar-layout="search"
      :queryParams="queryParams"
      search-property="user_name"
      placeHolderForSearch="Search_username"
      @loadData="handleLoadData"
      v-show="!loading"
    >
      <template v-slot:heading>
        <div class="main_header">
          <img src="@/assets/dealer/peoplegroup.svg" atl="down arrow" />
          <p style="margin-left: 10px">
            {{ `${$t("Comn_all")} ${$t("DL_customers")}` }}
          </p>
        </div>
      </template>
      <template slot="table">
        <defaultTable
          :tableData="tableData"
          :columns="columnsObject"
          :total="total"
          :key="dealer_id"
          :tableProps="elTableProps"
          :addPadingToSearch="true"
          @loadData="handleLoadData"
          @reload="handleLoadData"
          @cleared="handleLoadData"
        >
          <template v-slot:first_name_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:status_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:actions_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:tags_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:first_name="{ row }">
            {{ row.data.first_name }} {{ row.data.last_name }}<br />
            {{ row.data.email }}
          </template>
          <template v-slot:status="{ row }">
            <div
              :class="[
                'user-status-tag',
                getStatus(row.data.status).status === 'ACTIVE' && 'is-active' || getStatus(row.data.status).status === 'BLOCKED' && 'is-blocked'
              ]"
            >
              <p>{{ getStatus(row.data.status).lang }}</p>
            </div>
          </template>
          <template v-slot:actions="{ row }">
            <el-button-group>
              <er-button
                size="mini"
                btnType="download"
                :showIcon="false"
                :showLabel="true"
                :disabled="isReadOnly || row.data.status === 'BLOCKED'"
                @click="handleLoginAsUser($event, row.data._id)"
              >
                {{ $t("login_as_user") }}
              </er-button>
            </el-button-group>
          </template>
        </defaultTable>
      </template>
    </layout-dealer-card>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import defaultTable from "./defaultTable";
import layoutDealerCard from "./layoutDealerCard";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
export default {
  components: {
    defaultTable,
    layoutDealerCard
  },
  mixins: [errorHandlerMixin, errorKeyMapMixin, notificationMixin],
  props: ["dealer_id"],
  data: function () {
    return {
      tableData: [],
      selectedUsers: [],
      total: 0,
      dialogVisible: false,
      loading: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      queryParams: {},
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: {
        default: ""
      }
    };
  },

  computed: {
    ...mapGetters("dealer", {
      getDealerUsersDevices: "getDealerUsersDevices"
    }),
    elTableProps() {
      return {
        defaultSort: {
          prop: "first_name",
          order: "ascending"
        }
      };
    },
    columnsObject: function () {
      return [
        {
          label: "DL_customer_name",
          prop: "first_name",
          name: "first_name",
          required: true,
          sortable: true
        },
        {
          prop: "total_pond_mothers",
          label: "PondMothers",
          name: "PondMothers",
          required: true,
          sortable: false,
          minWidth: 70
        },
        {
          prop: "total_pond_guards",
          label: "PondGuards",
          name: "PondGuards",
          required: true,
          sortable: false,
          minWidth: 70
        },

        {
          prop: "total_shrimp_talks",
          label: "ShrimpTalks",
          name: "ShrimpTalks",
          required: true,
          sortable: false,
          minWidth: 70
        },
        {
          prop: "total_gateways",
          label: "Gateways",
          name: "Gateways",
          required: true,
          sortable: false,
          minWidth: 70
        },
        {
          prop: "status",
          name: "status",
          label: "status",
          required: true,
          sortable: true
        },
        {
          prop: "tags",
          label: "Usrs_tags",
          name: "tags",
          required: true,
          sortable: true
        },
        {
          label: "Comn_actions",
          name: "Actions",
          prop: "actions",
          required: true,
          minWidth: 100
        }
      ];
    }
  },
  methods: {
    ...mapActions("dealer", {
      fetchDealerUsersAndDevices: "fetchDealerUsersAndDevices",
      LoginAsUser: "LoginAsUser"
    }),
    getStatus(type) {
      if (type === "INACTIVE") {
        return { status: "INACTIVE", lang: this.$t("Comn_inactive") };
      } else if (type === "BLOCKED") {
        return { status: "BLOCKED", lang: this.$t("Comn_blocked") };
      } else {
        return { status: "ACTIVE", lang: this.$t("Comn_active") };
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.fetchDealerUsersAndDevices({
          params: query,
          infinite_scroll: false
        });
        this.tableData = this.getDealerUsersDevices;
        this.total = response.total;
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleAddDealerClick() {
      this.dialogVisible = true;
    },
    async handleLoginAsUser(event, userId) {
      const loading = this.$loading({ fullscreen: true });
      try {
        await this.LoginAsUser(userId);
        this.$router.push(
          "/user/",
          () => {
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("Welcome_to_pondlogs"),
              duration: 5000,
              type: "success"
            });
            loading.close();
          },
          () => {
            this.$notify({
              title: this.$t("failed"),
              message: this.$t("Something_went_wrong_try_again"),
              duration: 5000,
              type: "error"
            });
            loading.close();
          }
        );
      } catch (err) {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Something_went_wrong"),
          duration: 5000,
          type: "error"
        });
        loading.close();
      }
    }
  }
};
</script>

<style lang="scss">
.customer-details-table {
  height: 100%;
  .page_loading {
    @include responsiveProperty(height, 100vh, 100vh, 100vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .main_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
  .settings-table .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 80vh, 70vh, 76vh);
  }
}
</style>
