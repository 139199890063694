<template>
  <er-dialog
    width="30%"
    :visible="dialogVisible"
    @open="handleOpenDialog"
    @close="handleCloseDialog"
    :title="`Migrate to Other Dealer`"
    class="migrate-dealer-dialog"
  >
    <ValidationObserver ref="dialogListForm">
      <el-form size="mini">
        <el-form-item label="Current Dealer">
            <er-input
                :value="`${currDealer.first_name} ${currDealer.last_name}`"
            ></er-input>
        </el-form-item>
        <ValidationProvider
          name="Dealer Name"
          rules="required"
          v-slot="{ errors }"
        >
          <el-form-item label="Migrate To" :error="errors[0]">
            <er-select
              :placeholder="$t('Comn_search')"
              :loading="userListLoading"
              class="select-devices"
              v-model="selectedDealer"
              value-key="_id"
              collapse-tags
              filterable
              remote
              reserve-keyword
              :remote-method="fetchUsersToAssign"
            >
              <el-option
                v-for="item in usersList"
                :key="item._id"
                :label="
                  item.first_name +
                  '\xa0\xa0' +
                  item.last_name.concat('(' + item.email + ')')
                "
                :value="item"
              ></el-option>
            </er-select>
          </el-form-item>
        </ValidationProvider>
      </el-form>
    </ValidationObserver>

    <template slot="footer">
      <layout-toolbar>
        <er-button
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :disabled="saveLoading"
          @click="submitDealerDetails"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          @click="handleCloseDialog"
        ></er-button>
      </layout-toolbar>
    </template>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} is required",
});
export default {
  mixins: [errorHandlerMixin, notificationMixin],
  props: ["dialogVisible", "currDealer"],
  events: ["close"],
  data: function () {
    return {
      usersList: [],
      userListLoading: false,
      saveLoading: false,
      selectedDealer: null,
      userSearchQuery: {}
    };
  },
  computed: {
    ...mapGetters("superadmin", {
        getDealers: "getDealers"
    }),
  },
  methods: {
    ...mapActions("superadmin", {
        fetchAllDealers: "fetchAllDealers",
        migrateDealerResourcesToOther: "migrateDealerResourcesToOther"
    }),
    handleOpenDialog() {
        this.$nextTick(() => {
          this.usersList = []
          this.selectedDealer = null
          this.$refs.dialogListForm.reset();
        })
    },
    async fetchUsersToAssign(queryString) {
      if (queryString === "") {
        this.usersList = [];
        return this.usersList;
      }
      this.userListLoading = true;
      try {
        this.userSearchQuery.user_name = queryString;
        this.userSearchQuery.status = "ACTIVE";
        await this.fetchAllDealers({
          infinite_scroll: false,
          params: this.userSearchQuery,
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.usersList = this.getDealers.filter(x => x._id !== this.currDealer._id);
        this.userListLoading = false;
      }
    },
    async submitDealerDetails() {
      const response = await this.$refs.dialogListForm.validate();
      if (!response) return;
      this.loading = true;
      try {
        await this.migrateDealerResourcesToOther({ existingDealerId: this.currDealer._id, replacedDealerId: this.selectedDealer._id });
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: "Dealer Migration Started. A Email will be sent to the your email id",
          duration: 5000,
          type: "success",
        });
        this.handleCloseDialog();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleCloseDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.migrate-dealer-dialog {
  .el-form-item__label {
    width: 120px;
  }
  .el-form-item__content .el-input{
    width: 15rem;
  }
}
</style>
