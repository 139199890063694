<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: createNewCustomer.vue
Description:This is form where new Customer details are filled and submitted by admin
-->
<template>
  <er-dialog :title="getDialogTitle" :visible="visible" :modal-append-to-body="false" width="30%"
    :close-on-click-modal="false" @open="resetForm" @close="handleDialogClose" class="create-new-customer-sa">
    <!-- <el-alert
      v-if="ehm__unhandledErrorMessage"
      :title="$t(ehm__unhandledErrorMessage)"
      type="error"
      @close="ehm__handleAlertClose"
    ></el-alert> -->
    <el-form :ref="formName" :model="user" size="mini" v-loading="loading" element-loading-background="white"
      :element-loading-text="$t('Comn_auth_loading')">
      <el-form-item prop="first_name" class="name-element" :label="userFormLabels[0].label"
        :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.first_name)">
        <el-input v-model="user.first_name" :placeholder="userFormLabels[0].placeholder">
        </el-input>
      </el-form-item>

      <el-form-item prop="last_name" class="name-element" :label="userFormLabels[1].label"
        :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.last_name)">
        <el-input v-model="user.last_name" :placeholder="userFormLabels[1].placeholder">
        </el-input>
      </el-form-item>
      <el-form-item prop="email" :label="userFormLabels[2].label"
          :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)">
        <el-input v-model="user.email" :disabled="action === 'EDIT'" :placeholder="userFormLabels[2].placeholder">
        </el-input>
      </el-form-item>
      <!-- <el-form-item
        prop="country"
        label="Country"
        :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.country.name)"
      >
        <er-select
          v-model="user.country"
          value-key="code"
          autocomplete="new-country"
          filterable
          :placeholder="userFormLabels[3].placeholder"
        >
          <template slot="default">
            <el-option
              v-for="(name, code) in countries"
              :key="code"
              :label="name"
              :value="{ name, code }"
            ></el-option>
          </template>
</er-select>
</el-form-item> -->
      <el-form-item prop="phone" :label="userFormLabels[4].label"
        :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.phone)">
        <el-input class="country-select input-box" v-model="user.phone" :placeholder="userFormLabels[4].placeholder"
          autocomplete="off">
        </el-input>
      </el-form-item>
      <el-form-item v-if="action === 'ADD'" prop="password" :label="userFormLabels[5].label"
        :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.password)">
        <er-input v-model="user.password" :placeholder="userFormLabels[5].placeholder" show-password
          autocomplete="new-password">
        </er-input>
      </el-form-item>
      <el-form-item v-if="action === 'ADD'" prop="confirm_password" :label="userFormLabels[6].label" :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.confirm_password)
        ">
        <er-input v-model="user.confirm_password" :placeholder="userFormLabels[6].placeholder"
          @keyup.native.enter="submitForm" show-password>
        </er-input>
      </el-form-item>
    </el-form>

    <template slot="footer">
      <layout-toolbar class="form-action-wrapper">
        <el-row class="password-validations-container" v-if="shouldPassGuildelineDisplay">
          <span class="header">
            {{ $t("Usrs_pwd_requirement_header") }}
          </span>
          <ul class="validation-rules">
            <li v-for="(validation, id) in pvm__passwordValidations" :key="id" :class="[
              'validation-rules-row',
              validation.validator() && 'is-checked'
            ]">
              <div class="title">
                <p>{{ ftm__capitalize(validation.content) }}</p>
              </div>
            </li>
          </ul>
        </el-row>
        <div class="filler"></div>
        <!-- <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :disabled="isReadOnly"
          @click="sumbitUpdatePasswordDetails"
          :loading="loading"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="resetForm"
          :disabled="isReadOnly"
        ></er-button> -->
      </layout-toolbar>
      <el-row type="flex" justify="end" align="middle">
        <er-button size="mini" btnType="save" :showLabel="true" :showIcon="true" :loading="loading" @click="submitForm">
        </er-button>
        <er-button size="mini" btnType="cancel" :showLabel="true" @click="handleDialogClose">
        </er-button>
      </el-row>
    </template>
  </er-dialog>
</template>
<script>
import User from "@/model/skrettingtechnician.js";
import { mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin.js";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import passwordValidationMixin from "@/mixins/passwordValidationMixin";

export default {
  mixins: [
    errorHandlerMixin,
    redirectsMixin,
    authErrorKeyMapMixin,
    errorKeyMapMixin,
    passwordValidationMixin
  ],
  props: ["visible", "userType", "action", "model"],
  data: function () {
    return {
      user: new User(),
      countries: {},
      formName: "signUpForm",
      countryCode: "",
      loading: false,
      ehm__errMessagesObject: new User(),
      hasError: false,
      // userTypeMap: {
      //   CUSTOMER: { label: "Customer" },
      //   DEALER: { label: "Dealer" },
      //   ACCOUNTMANAGER: { label: this.$t("Account_manager") },
      //   SKRETTING_TECHNICIAN: { label: this.$t("skretting-technician") }
      // },
      ekmm__backndfieldToFieldNameMap: {
        email: "Comn_email_address",
        password: "Comn_password",
        first_name: "Usrs_first_name",
        last_name: "Usrs_last_name",
        confirm_password: "Comn_confirm_pwd"
      },

      aekmm__keyToInvalidTranslations: {
        email: {
          INVALID_VALUE: "email_in_valid_format",
          EMAIL_ALREADY_EXISTS: "Usrs_email_already_exists",
          ACCOUNT_ALREADY_TAKEN: "Usrs_email_already_exists",
          INVALID_USER_DATA: "Usrs_email_already_exists"
        },

        first_name: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Min 3 characters Required"
        },
        last_name: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Min 3 characters Required"
        },
        password: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Password Should contain min 6 characters"
        },
        confirm_password: {
          VALUES_SHOULD_MATCH: "Both password and confirm password must match",
          PASSWORD_CONFIRM_PASSWORD_NOT_SAME:
            "Both password and confirm password must match"
        },
        phone: { INVALID_VALUE: "Comn_phone_num_valid" },
      }
    };
  },
  computed: {
    getDialogTitle() {
      const userName = this.userTypeMap[this.userType].label;
      return this.action === "ADD"
        ? `${this.$t("Comn_create")} ${userName}`
        : `${this.$t("Comn_edit")} ${userName}`;
    },
    userTypeMap() {
      return {
        CUSTOMER: { label: "Customer" },
        DEALER: { label: "Dealer" },
        ACCOUNTMANAGER: { label: this.$t("Account_manager") },
        SKRETTING_TECHNICIAN: { label: this.$t("skretting-technician") }
      };
    },
    getTimeZones() {
      return this.$store.getters["user/getTimeZones"];
    },
    shouldPassGuildelineDisplay() {
      return this.action !== "EDIT";
    },
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        {
          label: this.$t("Comn_country"),
          placeholder: this.$t("Usrs_enter_country")
        },
        {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        {
          label: this.$t("Usrs_pwd"),
          placeholder: this.$t("Usrs_enter_pwd")
        },
        {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd")
        }
      ];
    }
  },
  async mounted() {
    try {
      await this.fetchCountryList();
      this.countries = this.$store.getters["auth/getCountries"];
    } catch (err) {
      this.ehm__errorMessages(err, false);
    }
  },
  methods: {
    ...mapActions("auth", {
      signUp: "signUp",
      fetchCountryList: "fetchCountryList",
      AddDealer: "AddDealer",
      EditDealer: "EditDealer",
      AddAccountManager: "AddAccountManager",
      EditAccountManager: "EditAccountManager"
    }),
    ...mapActions("superadmin", {
      AddDealer: "AddDealer",
      AddAccountManager: "AddAccountManager",
      EditAccountManager: "EditAccountManager",
      EditDealer: "EditDealer"
    }),
    ...mapActions("accountManager", {
      addSkrettingTechnicians: "addSkrettingTechnicians",
      editSkrettingTechnicians: "editSkrettingTechnicians"
    }),

    ehm__error409Handler: function (err) {
      // let errorInnerHtml = ''
      if (err.response.data.errors != null) {
        const errorDetails = err.response.data.errors.details;
        errorDetails.forEach((el, index) => {
          // errorInnerHtml += '<li>' + el.message + '</li>'
          this.ehm__unhandledErrorMessage +=
            (index === 0 ? "" : ",") +
            el.message +
            (errorDetails.length - 1 === index ? "" : ",");
        });
      } else {
        err.response.data.key = "email";
        this.ehm__errMessagesObject.email = err.response.data;
        console.log(this.ehm__errMessagesObject.email);
      }
    },
    ehm__error403Handler: function (error) {
      const errorData = error.response.data;
      if (errorData.error_code === "ACCOUNT_ALREADY_TAKEN") {
        this.$notify({
          title: this.$t("failed"),
          message: errorData.message,
          type: "error"
        });
      } else {
        this.ehm__errMessagesObject.email = {
          key: "email",
          ...error.response.data
        };
        this.ehm__errMessagesObject = Object.assign(
          {},
          this.ehm__errMessagesObject
        );
      }
    },
    resetForm() {
      this.ehm__errMessagesObject = new User();
      this.user =
        this.action === "ADD" ? new User() : this.$lodash.cloneDeep(this.model);
    },
    handleDialogClose(message) {
      this.$emit("close", message);
    },
    async handleCountryChange(selectedCountry) {
      try {
        await this.$store.dispatch("user/fetchTimeZones", selectedCountry.code);
        this.timezones = this.getTimeZones;
        this.user.timezone = this.timezones[Object.keys(this.getTimeZones)[0]];
      } catch (err) {
        console.error(err);
      }
    },
    async submitForm() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const payload = {};
        if (this.action === 'EDIT') {
          payload.first_name = this.upm__trimSpacesInStr(this.user.first_name);
          payload.last_name = this.upm__trimSpacesInStr(this.user.last_name);
          payload.email = this.upm__trimSpacesInStr(this.user.email);
          payload.phone = this.upm__trimSpacesInStr(this.user.phone);
          payload._id = this.user._id;
        }
        this.user.first_name = this.upm__trimSpacesInStr(this.user.first_name);
        this.user.last_name = this.upm__trimSpacesInStr(this.user.last_name);
        this.user.email = this.upm__trimSpacesInStr(this.user.email);
        this.user.phone = this.upm__trimSpacesInStr(this.user.phone);
        this.ehm__errMessagesObject = new User();

        const actionToCall = {
          DEALER_ADD: this.AddDealer,
          DEALER_EDIT: this.EditDealer,
          CUSTOMER_ADD: this.signUp,
          ACCOUNTMANAGER_ADD: this.AddAccountManager,
          ACCOUNTMANAGER_EDIT: this.EditAccountManager,
          SKRETTING_TECHNICIAN_ADD: this.addSkrettingTechnicians,
          SKRETTING_TECHNICIAN_EDIT: this.editSkrettingTechnicians
        };

        await actionToCall[`${this.userType}_${this.action}`](this.action === 'EDIT' ? payload : this.user);
        let preTitle = "";
        switch (this.action) {
          case "ADD":
            preTitle = this.$t("skretting-technician-added");
            break;
          case "EDIT":
            preTitle = this.$t("skretting-technician-edited");
            break;
          default:
            preTitle = this.$t("skretting-technician-deleted");
            break;
        }
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: preTitle,
          duration: 10000,
          type: "success"
        });
        console.log("this.action", this.action);
        this.handleDialogClose("user_created");
      } catch (err) {
        this.ehm__errorMessages(err, false);
        // this.$notify({
        //   title: "Failed",
        //   message: "Failed to create a customer",
        //   duration: 10000,
        //   type: "error"
        // });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
// input {
//   text-transform: lowercase;
// }
// .settings-table .search-input .el-input__inner {
//   text-transform: lowercase !important;
// }
.create-new-customer-sa {
  .el-dialog__footer {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end;
  }

  .el-form {
    display: grid;
  }

  .el-form-item.el-form-item--mini {
    display: grid;
    grid-template-columns: 1fr 2.4fr;
    grid-template-rows: 1fr;

    .el-form-item__label {
      grid-area: 1 / 1 / 2 / 2;
    }

    .el-form-item__content {
      grid-area: 1 / 2 / 2 / 3;

      .el-input-number {
        width: 100%;
      }

      .el-date-editor {
        width: 100%;
      }
    }
  }
}

.country-code-popper {
  width: 10% !important;
  margin-top: 5px;

  li {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    line-height: 1;

    .country {
      width: 30%;
      margin: 0px 10px;
      right: 0%;
      text-align: left;
    }

    .country-code {
      width: 70%;
      margin: 0px 10px;
      left: 0%;
    }
  }

  .popper__arrow {
    left: 12% !important;
  }
}
</style>
