<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: shrimpSnapTableSA.vue
Description:This file contains the details of the shrimpsnaps and add the feed blower too by admin
-->
<template>
  <layout class="shrimp-snap-table-sa" :no-aside="true">
    <tab-layout
      :componentList="componentList"
      :showComponent="urlParams.tab"
      :tab-click-handler="handleTabClick"
      @selectedComponent="handleSelectedComponent"
    >
      <template slot="shrimpSnapDetails">
        <dialog-form
          :model="getNewShrimpSnap()"
          :schema="shrimpsnapFields"
          :showDialog="dialogVisible"
          :loading="dialogBoxLoading"
          :backendValidations="createShrimpSnapFormErrors"
          title="Add ShrimpSnap"
          :v1-code-length="6"
          @close-dialog="closeDialog"
          @save-details="handleSaveNewShrimpSnap"
          @clear-validations="handleClearValidations"
          deviceType="SHRIMP_SNAP"
          :shouldErrorHighlightShow="true"
        ></dialog-form>
        <settings-table
          ref="erDataTables"
          :tableProps="elTableProps"
          v-loading="loading"
          element-loading-background="white"
          :tableData="tableData"
          :columns="columnsObject"
          :addPadingToSearch="true"
          search-property="device_code"
          placeHolderForSearch="Search_device_code"
          :total="total"
          :button="true"
          :columnselector_require="true"
          :column-selector-options="{
            keyToStoreDefaults: 'shrimpsnap-table-sa',
            keyToDecideDefaultCol: 'required',
          }"
          :selectedColumnHeadersSortable="true"
          @loadData="handleLoadData"
          @reload="handleLoadData"
          @cleared="handleLoadData"
          @add-item-click="handleAddClick"
        >
          <template slot="add-buttton">Add ShrimpSnap</template>
          <template v-slot:shrimpsnap_id="{ row }">
            <el-link :href="`/admin/shrimpsnaps/${row.data._id}`">
              SS{{ row.data.v1_code }}
            </el-link>
          </template>
          <template v-slot:hex_id="{ row }">
            <el-link :href="`/admin/shrimpsnaps/${row.data._id}`">
              {{ row.data.code }}
            </el-link>
          </template>
          <template v-slot:user_details="{ row }">
            {{ row.data.user_id ? row.data.user_details.full_name : "--" }}
          </template>
          <template v-slot:dealer_details="{ row }">
            {{ row.data.user_id ? row.data.dealer_details.full_name : "--" }}
          </template>
          <template v-slot:location_details="{ row }">
            {{ row.data.user_id ? row.data.location_details.name : "--" }}
          </template>
          <template v-slot:last_communication="{ row }">
            <el-tag
              size="mini"
              type="success"
              effect="dark"
              v-if="row.data.utc_last_communicated_at_status"
            >
              {{
                ftm__formatEmpty(
                  adm__filterToFormatDate(row.data.utc_last_communicated_at)
                )
              }}
            </el-tag>
            <template v-else>
              {{
                ftm__formatEmpty(
                  adm__filterToFormatDate(row.data.utc_last_communicated_at)
                )
              }}
            </template>
          </template>
          <template v-slot:installed_date="{ row }">
            {{
              ftm__formatEmpty(adm__filterToFormatDate(row.data.on_boarded_at))
            }}
          </template>
        </settings-table>
      </template>
    </tab-layout>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import notificationMixin from "@/mixins/notificationMixin";
import layout from "@/components/superadmin/shared/layout";
import tabLayout from "@/components/superadmin/shared/tabLayout";
import { mapActions } from "vuex";
import dialogForm from "@/components/superadmin/shared/dialogForm";
export default {
  mixins: [
    errorHandlerMixin,
    notificationMixin,
    adminDatesHandlerMixin,
    filtersMixin
  ],
  data: function() {
    return {
      tableData: [],
      total: 0,
      queryParams: {},
      loading: false,
      dialogBoxLoading: false,
      componentName: "shrimpSnapDetails",
      createShrimpSnapFormErrors: {},
      dialogVisible: false,
      urlParams: {
        tab: "shrimpSnapDetails",
        ...this.$route.query
      },
      componentList: [
        {
          name: "shrimpSnapDetails",
          label: "ShrimpSnap Details"
        }
      ]
    };
  },
  components: {
    settingsTable,
    layout,
    tabLayout,
    dialogForm
  },
  watch: {
    urlParams: {
      deep: true,
      handler: function(newValue, oldValue) {
        this.$router.push({ path: "/admin/shrimpsnaps", query: newValue });
      }
    }
  },
  computed: {
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      return "calc(100vh - 180px)";
    },

    shrimpsnapFields: function() {
      return [
        {
          type: "datePicker",
          inputType: "string",
          label: "Installation Date",
          model: "on_boarded_at",
          validationRules: "required"
        }
      ];
    },
    columnsObject: function() {
      return [
        {
          prop: "v1_code",
          label: "ShrimpSnap Id",
          required: true,
          sortable: true,
          minWidth: 120,
          fixed: "fixed"
        },
        {
          prop: "code",
          label: "Hex Id",
          required: true,
          sortable: true,
          minWidth: 120,
          // fixed: "fixed"
        },
        {
          prop: "utc_last_communicated_at",
          label: "Last Communication",
          required: true,
          sortable: "custom",
          type: "date",
          minWidth: 150
        },
        {
          prop: "settings.ip_address",
          label: "IP Address",
          required: true,
          sortable: true,
          minWidth: 120,
          // fixed: "fixed"
        },
        {
          prop: "settings.external_ip_address",
          label: "External IP Address",
          required: true,
          sortable: true,
          minWidth: 150,
          // fixed: "fixed"
        },
        {
          prop: "settings.mac_address",
          label: "MAC Address",
          required: true,
          sortable: true,
          minWidth: 120,
          // fixed: "fixed"
        },
        {
          prop: "settings.os_version",
          label: "OS Version",
          required: true,
          sortable: true,
          minWidth: 120,
          // fixed: "fixed"
        },
        {
          prop: "settings.software_version",
          label: "SW Version",
          required: true,
          sortable: true,
          minWidth: 120,
          // fixed: "fixed"
        },
        {
          prop: "settings.hardware_version",
          label: "HW Version",
          required: true,
          sortable: true,
          minWidth: 120,
          // fixed: "fixed"
        },
        {
          prop: "_id",
          label: "v2 ID",
          required: false,
          sortable: true,
          minWidth: 120,
          // fixed: "fixed"
        },
        {
          prop: "user_details.full_name",
          label: "Customer Name",
          required: true,
          sortable: true,
          minWidth: 150,
          // fixed: "fixed"
        },
        {
          prop: "dealer_details.full_name",
          label: "Dealer Name",
          required: true,
          minWidth: 200,
          // fixed: true
        },
        {
          prop: "location_details.name",
          label: "Location",
          required: true,
          minWidth: 200,
          // fixed: true
        },
        {
          prop: "on_boarded_at",
          label: "Installed Date",
          required: false,
          sortable: true,
          minWidth: 120,
          // fixed: "fixed"
        }
      ];
    }
  },

  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      updateASettingKey: "updateASettingKey",
      fetchAllShrimpSnaps: "fetchAllShrimpSnaps"
    }),
    getNewShrimpSnap() {
      return {
        on_boarded_at: this.adm__dateUtilsLib.formatDate(
          new Date(),
          "yyyy-MM-dd"
        )
      };
    },
    closeDialog() {
      this.createShrimpSnapFormErrors = {};
      this.dialogVisible = false;
    },
    handleClearValidations(type, indexPosition) {
      switch (type) {
        case "ALL":
          this.createShrimpSnapFormErrors = {};
          break;
        case "SINGLE":
          delete this.createShrimpSnapFormErrors[indexPosition];
          break;
      }
    },
    handleAddClick() {
      this.dialogVisible = true;
    },
    async handleSaveNewShrimpSnap(model, arrShrimpSnapNames) {
      try {
        this.dialogBoxLoading = true;
        const payload = arrShrimpSnapNames.map((shrimpSnapName) => {
          return {
            v1_code: shrimpSnapName,
            on_boarded_at: model.on_boarded_at
          };
        });
        await this.saveListDevicesData({ device_type: "SHRIMP_SNAP", payload });
        this.$notify({
          title: "Success",
          message: "Created ShrimpSnap Successfully",
          type: "success"
        });
        this.dialogVisible = false;
      } catch (err) {
        this.$notify({
          title: "Failed",
          message: "Created ShrimpSnap UnSuccessful",
          type: "error"
        });
        if (err.response && err.response.data) {
          this.createShrimpSnapFormErrors = err.response.data.errors.details.reduce(
            (acc, err) => {
              acc[err.key] = err.message;
              return acc;
            },
            {}
          );
        }
      } finally {
        this.dialogBoxLoading = false;
        await this.handleLoadData(this.queryParams);
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        if (
          query.device_code &&
          query.device_code.toUpperCase().startsWith("SS")
        ) {
          query.device_code = query.device_code.substr(2).trim();
        }
        const response = await this.fetchAllDeviceDetails({
          device_type: "shrimp_snap",
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.$store.getters["superadmin/getShrimpSnaps"];
        console.log("this.tableData", this.tableData);

        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.erDataTables) {
            console.log(this.$refs.erDataTables.$refs.dataTable.$refs.elTable);
            this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    handleTabClick(tab) {
      this.clearDeviceDetails({
        device_type: "shrimp_snap"
      });
    },
    handleSelectedComponent(component) {
      this.componentName = component;
      this.urlParams = { tab: component };
    }
  }
};
</script>

<style lang="scss">
.shrimp-snap-table-sa {
  .el-tabs__item {
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
  }
  .el-table__fixed {
    height: calc(100vh - 188px) !important;
  }
  .el-tag {
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
  }
}
</style>
