<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: AddCustomers.vue
Description:This file contains the details  of the customers associated with the dealer and add the new customers by superadmin
-->
<template>
  <div class="add-customer">
    <er-dialog
      width="30%"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      @open="handleOpenDialog"
      :title="$t('Comn_add_customer')"
      custom-class="add-customer-dialog"
    >
      <ValidationObserver ref="dialogListForm">
        <el-form size="large">
          <ValidationProvider
            name="User Name"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item label="" :error="errors[0]">
              <er-select
                :placeholder="$t('Comn_search')"
                :loading="userListLoading"
                class="select-devices"
                :showSelectAll="true"
                v-model="selectedUsers"
                value-key="_id"
                collapse-tags
                multiple
                filterable
                remote
                reserve-keyword
                :remote-method="fetchUsersToAssign"
              >
                <el-option
                  v-for="item in usersList"
                  :key="item._id"
                  :label="
                    item.first_name +
                      '\xa0\xa0' +
                      item.last_name.concat('(' + item.email + ')')
                  "
                  :value="item"
                ></el-option>
              </er-select>
            </el-form-item>
          </ValidationProvider>
        </el-form>
      </ValidationObserver>

      <template slot="footer">
        <er-button
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          @click="submitUserDetails"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          :disabled="saveLoading"
          @click="handleCloseDialog"
        ></er-button>
      </template>
    </er-dialog>

    <settings-table
      v-loading="loading"
      element-loading-background="white"
      :tableData="tableData"
      :columns="columnsObject"
      :total="total"
      :button="getParticularDealer.status === 'ACTIVE'"
      :key="dealer_id"
      placeHolderForSearch="Search_username"
      search-property="user_name"
      :addPadingToSearch="true"
      :columnselector_require="false"
      @loadData="handleLoadData"
      @reload="handleLoadData"
      @cleared="handleLoadData"
      @add-item-click="handleAddDealerClick"
    >
      <template slot="add-buttton">Add Customer</template>

      <template v-slot:name="{ row }">
        {{ row.data.first_name }} {{ row.data.last_name }}
      </template>

      <template v-slot:actions="{ row }">
        <el-button-group>
          <er-button size="mini" @click="handleUserDelete(row.data)">
            <span class="material-icons-round"> delete </span>
          </er-button>
        </el-button-group>
      </template>
    </settings-table>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import settingsTable from "@/components/base/settingsTable";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin, notificationMixin],
  props: ["dealer_id"],
  data: function() {
    return {
      tableData: [],
      userSearchQuery: {},
      usersList: [],
      userListLoading: false,
      selectedUsers: [],
      total: 0,
      clientActionDialogTitle: "Add",
      dialogVisible: false,
      loading: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      queryParams: {},
      newClient: {},
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: {
        default: ""
      },
      countries: {},

      newErrObject: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        confirm_password: "",
        country: {
          name: "",
          code: ""
        },
        timezone: {
          name: "",
          offset: ""
        }
      },
      ehm__errMessagesObject: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        confirm_password: "",
        country: {
          name: "",
          code: ""
        },
        timezone: {
          name: "",
          offset: ""
        }
      },
      formDealer: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        confirm_password: "",
        country: {
          name: "",
          code: ""
        },
        timezone: {
          name: "",
          offset: ""
        }
      },
      errorObject: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        confirm_password: "",
        country: {
          name: "",
          code: ""
        },
        timezone: {
          name: "",
          offset: ""
        }
      }
    };
  },
  components: {
    settingsTable
  },
  computed: {
    ...mapGetters("superadmin", {
      getUnassignedUsers: "getUnassignedUsers",
      getDealerUsers: "getDealerUsers",
      getParticularDealer: "getParticularDealer"
    }),
    columnsObject: function() {
      return [
        {
          label: "Name",
          prop: "first_name",
          required: true,
          sortable: true
        },
        {
          prop: "email",
          label: "Email Address",
          required: true,
          sortable: true
        },
        {
          prop: "country.name",
          label: "Country",
          required: true,
          sortable: true
        },
        {
          prop: "phone",
          label: "phone",
          required: true,
          sortable: true
        },
        {
          prop: "tags",
          label: "Tag",
          required: true,
          sortable: true
        },
        {
          label: "Actions",
          required: true
        }
      ];
    },
    formStrings() {
      return {
        email: {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        first_name: {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        last_name: {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        name: {
          label: this.$t("Comn_country"),
          placeholder: this.$t("Usrs_enter_country")
        },
        phone: {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        password: {
          label: this.$t("Usrs_pwd"),
          placeholder: this.$t("Usrs_enter_pwd")
        },
        confirm_password: {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd")
        }
      };
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllUnAssignedUsers: "fetchAllUnAssignedUsers",
      assignUsersToDealer: "assignUsersToDealer",
      removeAssignedUsersToDealer: "removeAssignedUsersToDealer",
      alreadyAssignedUsersToDealer: "alreadyAssignedUsersToDealer"
    }),
    getNewClient() {
      return {
        title: undefined,
        users: []
      };
    },
    async handleUserDelete(removeuserDetails) {
      const payload = {
        dealer_id: this.dealer_id,
        user_ids: [removeuserDetails._id]
      };
      try {
        const confirm_message = removeuserDetails.first_name;
        await this.nofm__deleteConfirmation(confirm_message, "table");
        this.loading = true;
        await this.removeAssignedUsersToDealer(payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: "Users deleted Successfully",
          duration: 5000,
          type: "success"
        });
      } catch (err) {
        const errors = [];
        if (err.response && err.response.data.message) {
          errors.push({ message: err.response.data.message });

          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        await this.handleLoadData(this.queryParams);
        this.loading = false;
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.alreadyAssignedUsersToDealer({
          infinite_scroll: false,
          dealer_id: this.dealer_id,
          params: query
        });
        this.tableData = this.$store.getters["superadmin/getDealerUsers"];
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },

    async fetchUsersToAssign(queryString) {
      if (queryString === "") {
        this.usersList = [];
        return this.usersList;
      }
      this.userListLoading = true;
      try {
        this.userSearchQuery.user_name = queryString;
        await this.fetchAllUnAssignedUsers({
          infinite_scroll: false,
          params: this.userSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.usersList = this.getUnassignedUsers;
        this.userListLoading = false;
      }
    },

    handleAddDealerClick() {
      this.actionToPerform = "add";
      this.selectedUsers = "";
      this.dialogVisible = true;
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    handleOpenDialog(event) {
      this.selectedUsers = [];
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
    },
    async submitUserDetails() {
      const response = await this.$refs.dialogListForm.validate();
      if (!response) return;
      const payload = {
        dealer_id: this.dealer_id,
        user_ids: this.selectedUsers.map(x => x._id)
      };
      this.loading = true;
      try {
        await this.assignUsersToDealer(payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: "Customer Added Successfully",
          duration: 5000,
          type: "success"
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.dialogVisible = false;
        await this.handleLoadData(this.queryParams);
      }
    },

    handleAddClientDialogOpen() {
      delete this.userQuery.user_name;
      this.usersList = [];
    }
  }
  //   async initComponent() {
  //     try {
  //       this.loading = true;
  //       await this.fetchAllUnAssignedUsers({
  //         infinite_scroll: false,
  //         params: { page: 1, limit: 10, order_by: "email", order_type: "asc" }
  //       });
  //     } catch (err) {
  //       this.ehm__errorMessages(err, true);
  //     } finally {
  //       this.loading = false;
  //     }
  //   }
  // },
  // async mounted() {
  //   await this.initComponent();
  // }
};
</script>

<style lang="scss">
.add-customer {
  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
  .settings-table {
    .el-table {
      .el-table__body-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 0;
        height: calc(100vh - 286px) !important;
        overflow-y: auto;
      }
    }
  }
  .settings-table .settings-table-scroll {
    position: relative;
    // @include responsiveProperty(height, 75vh, 79vh, 85vh);
    @include responsiveProperty(height, 64vh, 70vh, 76vh);
  }
  .el-row--flex.is-justify-end {
    padding-right: 10px;
  }
}
.select-devices.el-select {
  display: flex;
}
.add-customer-dialog {
  border-radius: 10px;
}
</style>
