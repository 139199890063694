var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"customer-details-table",attrs:{"element-loading-background":"white"}},[(_vm.loading)?_c('div',{staticClass:"page_loading"}):_vm._e(),_vm._v(" "),_c('layout-accountmanager-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"toolbar-layout":"search","search-property":"user_name","placeHolderForSearch":"Search_username"},on:{"loadData":_vm.handleLoadData},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{attrs:{"src":require("@/assets/dealer/peoplegroup.svg"),"atl":"down arrow"}}),_vm._v(" "),_c('p',{staticStyle:{"margin-left":"20px"}},[_vm._v("\n          "+_vm._s(((_vm.$t("Comn_all")) + " " + (_vm.$t("DL_customers"))))+"\n        ")])])]},proxy:true}])},[_vm._v(" "),_c('template',{slot:"table"},[_c('defaultTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"total":_vm.total,"tableProps":_vm.elTableProps,"placeHolderForSearch":"Search_username","addPadingToSearch":true},on:{"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData},scopedSlots:_vm._u([{key:"first_name_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"email_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"actions_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"country_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"phone_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"status_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"tags_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"uiedit_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"ui_edit",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"value":(row.data.device_config || {}).device_ui_edit,"active-color":"#13ce66","inactive-color":"#ff4949"},on:{"input":function($event){return _vm.handleEnableFeature($event, row.data, 'device_ui_edit')}}})]}},{key:"first_name",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.first_name)+"\n          "+_vm._s(row.data.last_name)+"\n        ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',{class:[
              'user-status-tag',
              _vm.getStatus(row.data.status).status === 'ACTIVE' && 'is-active' || _vm.getStatus(row.data.status).status === 'BLOCKED' && 'is-blocked'
            ]},[_c('p',[_vm._v(_vm._s(_vm.getStatus(row.data.status).lang))])])]}},{key:"actions",fn:function(ref){
            var row = ref.row;
return [_c('el-button-group',[_c('er-button',{attrs:{"size":"mini","btnType":"download","title":_vm.$t('Harvest_log_in'),"showIcon":false,"disabled":_vm.isReadOnly || row.data.status === 'BLOCKED',"showLabel":true},on:{"click":function($event){return _vm.handleLoginAsUser($event, row.data._id)}}},[_vm._v("\n              "+_vm._s(_vm.$t("login_as_user"))+"\n            ")])],1)]}}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }